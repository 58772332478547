import Image from 'react-bootstrap/Image';
import { motion } from "framer-motion";

import acmeLogo from '../LandingPage/assets/images/acme.png';

import quantumLogo from "../LandingPage/assets/images/quantum.png";
import echoLogo from "../LandingPage/assets/images/echo.png";
import celestialLogo from "../LandingPage/assets/images/celestial.png";
import pulseLogo from "../LandingPage/assets/images/pulse.png";
import apexLogo from "../LandingPage/assets/images/apex.png";

const images = [
    { src: acmeLogo, alt: "Acme Logo" },
    { src: quantumLogo, alt: "Quantum Logo" },
    { src: echoLogo, alt: "Echo Logo" },
    { src: celestialLogo, alt: "Celestial Logo" },
    { src: pulseLogo, alt: "Pulse Logo" },
    { src: apexLogo, alt: "Apex Logo" },
];

export const LogoTicker = () => {
    return (
        <div className="bg-black text-white py-18 sm:py-24">
            <div className="container">
                <h2 className="text-xl text-center text-white/70">Trusted by the world's most innovative teams</h2>
                <div className="flex overflow-hidden mt-9 relative before:absolute after:absolute before:h-full before:w-10 after:h-full after:w-10 before:left-0 after:right-0 before:bg-gradient-to-r from-black to-transparent after:bg-gradient-to-l from-black to-transparent">
                    <motion.div transition={{ duration: 12, ease: "linear", repeat: Infinity }} initial={{ translateX: 0 }} animate={{ translateX: "-50%" }} className="flex gap-16 flex-none pr-16">
                        {images.map((image, index) => (
                            <Image key={index} src={image.src} alt={image.alt} className="flex-none h-8 w-auto" />
                        ))}
                        {images.map((image, index) => (
                            <Image key={index} src={image.src} alt={image.alt} className="flex-none h-8 w-auto" />
                        ))}
                    </motion.div>
                </div>
            </div>
        </div>
    );
};
