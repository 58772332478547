import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DateTimePicker from '../Components/DateAndTime';
import FacebookPost from '../PostsUI/FacebookPost';
import TwitterPost from '../PostsUI/TwitterPost';
import InstagramPost from '../PostsUI/InstagramPost';
import PinterestPost from '../PostsUI/PinteresPost';
import LinkedInPost from '../PostsUI/LinkedInPost';
import MyCalendar from '../Components/BigCalendar';
import FacebookBigPost from '../PostsUI/FacebookBigPost';
import TwitterBigPost from '../PostsUI/TwitterBigPost';
import InstagramBigPost from '../PostsUI/InstagramBigPost';
import LinkedInBigPost from '../PostsUI/LinkedInBigPost';
import PinterestBigPost from '../PostsUI/PinterestBigPost';
import { FaFacebook, FaTwitter, FaPinterest, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { ConfettiSideCannons } from '../Components/SideCanonsConfetti';
import useAnalytics from '../hooks/useAnalytics';

const SocialMedia = () => {
  const location = useLocation();
  const { image, caption } = location.state || {};
  const navigate = useNavigate();
  const { logCustomEvent } = useAnalytics();  // Move this to the top level

  const [dataResponse, setDataResponse] = useState(null);
  const [input, setInput] = useState(caption || '');
  const [dropDown, setDropDown] = useState(false);
  const [isCalendar, setIsCalendar] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(null);
  const [events, setEvents] = useState(null);
  const [SelectedPostComponent, setSelectedPostComponent] = useState(null);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [isCardVisible, setIsCardVisible] = useState(false); // State for card visibility
  const confettiRef = useRef(null); // Ref for ConfettiSideCannons

  const handleScheduleClick = () => {
    setIsCalendar(!isCalendar);
    setDropDown(false);
  };

  const handleEdit = (e) => {
    setInput(e.target.value);
  };

  const handleDateTimeSave = (date) => {
    setScheduleDate(date);
    setIsCalendar(false);
    if (input) {
      console.log('Scheduled Event Date:', date);
      setEvents({ input, start: date, end: date });
      console.log('Events:', events);
    }
  };

  const handleCardClick = (PostComponent) => {
    setSelectedPostComponent(() => PostComponent);
  };

  const toggleDropdown = () => {
    setDropDown(!dropDown);
  };

  const handlePlatformSelect = (platform) => {
    if (selectedPlatforms.includes(platform)) {
      setSelectedPlatforms(selectedPlatforms.filter((p) => p !== platform));
    } else {
      setSelectedPlatforms([...selectedPlatforms, platform]);
    }
  };

  const handleViewEventClick = () => {
    sendScheduledData();
    if (confettiRef.current) {
      confettiRef.current.triggerConfetti(); // Trigger confetti on publish
    }
    setIsCardVisible(true); // Show the card
  };

  const closeCard = () => {
    setIsCardVisible(false); // Hide the card
  };

  const sendScheduledData = async () => {
    console.log('Scheduled Data:', events);

    const data = await fetch('https://social.onetapaisolutions.com/api/saveGeneratedPost', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('ai-tk')}`,
      },
      body: JSON.stringify({ image: image[0], caption: caption, date: events.start, platform: ["instagram"] }),
    });

    // check if the response is ok
    if (data.ok) {
      // navigate('/scheduler')
    } else {
      console.log('Post not scheduled');
    }

    logCustomEvent('social_post_scheduled', {
      platform: "instagram",
      has_image: !!image[0],
      caption_length: caption.length,
      scheduled_time: events.start
    });
  };

  return (
    <div className="p-8">
      <p className="font-bold text-2xl">AI Social Post Generator</p>

      <div className="flex mt-10">
        <div className="w-1/2">
          <div className="border border-gray-300 rounded-lg p-4">
            <div className='flex justify-between'>
              <p className="font-medium text-xl text-orange-400">Edit here</p>
              <div className="flex space-x-2">
                <button
                  className="px-4 py-2 rounded-xl bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white relative"
                  onClick={() => navigate('/profile')}
                >
                  Connect Profile
                </button>

              </div>
            </div>
            <textarea
              value={input}
              onChange={handleEdit}
              className="w-full mt-4 p-2 border border-gray-300 rounded-lg"
              rows="5"
            ></textarea>



            <div className="flex justify-between mt-4 items-center">
              <div className='flex'>
                <div className="flex relative">
                  <button
                    className="px-4 py-2 rounded-xl bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white mr-2"
                    onClick={handleScheduleClick}
                  >
                    Schedule
                  </button>
                  {isCalendar && (
                    <div className="fixed z-20 inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
                      <DateTimePicker
                        onClose={() => setIsCalendar(false)}
                        onSave={handleDateTimeSave}
                      />
                    </div>
                  )}
                </div>
                <ConfettiSideCannons ref={confettiRef} /> {/* Attach ref to ConfettiSideCannons */}
                <button
                  className="px-4 py-2 rounded-xl bg-gradient-to-r from-[#2091DC] to-[#16B197] text-white ml-2"
                  onClick={handleViewEventClick}
                >
                  Publish Content
                </button>
              </div>
            </div>
          </div>

        </div>
        <div className="w-1/2 ml-4 bg-gray-100">
          <div className="border border-gray-300 rounded-lg p-4">
            <p className="font-bold text-lg font-poppins">Preview</p>
            <div className="w-10/12 ml-16 flex justify-center items-center bg-white border border-gray-200 rounded-lg overflow-hidden shadow-md">
              {SelectedPostComponent ? (
                <div className="transform scale-105 w-full">
                  <SelectedPostComponent image={image} caption={input} />
                </div>
              ) : (
                <div className="text-gray-500 w-1/2 h-96 flex justify-center items-center">Select a post to preview</div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Card for successful post publish */}
      {isCardVisible && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Success</h2>
            <p>Your Post has been Published Successfully 💐</p>
            <button
              className="mt-4 px-4 py-2 bg-gradient-to-r from-[#2091DC] to-[#16B197] text-white rounded-lg"
              onClick={closeCard}
            >
              Close
            </button>
          </div>
        </div>
      )}

    </div>
  );
};

export default SocialMedia;
