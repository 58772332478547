import React from 'react';
import { useLocation } from 'react-router-dom';

function LinkedInBigPost({image, caption}) {
  
  return (
    <div className="max-w-md mx-auto bg-white border rounded-lg shadow-md p-4 mt-2">
      <div className="flex items-center mb-4">
        <img
          className="w-10 h-10 rounded-full"
          src="https://via.placeholder.com/150"
          alt="Profile"
        />
        <div className="ml-3">
          <div className="flex items-center">
            <span className="font-bold text-gray-800">Pushkar Kumar</span>
            <span className="text-gray-500 ml-1">• 3rd+</span>
          </div>
          <div className="text-gray-500 text-sm">
            Sr SDE @ByteDance Seattle, Ex-Amazon | YouTuber
          </div>
          <div className="text-gray-500 text-sm">1w • <span className="inline-block align-middle"><i className="fas fa-globe"></i></span></div>
        </div>
      </div>
      <p className="text-gray-800 mb-4">
        {caption}
      </p>
      <div className="rounded-lg overflow-hidden mb-4">
        <img
          className="w-full"
          src={image}
          alt="LinkedIn Post"
        />
      </div>
      <div className=" mt-4 text-gray-500">
        <span>Vimal Tripathi and 4,344 others</span>
        <span className="flex justify-center space-x-8">
          <button className="hover:underline">Like</button>
          <button className="hover:underline">Comment</button>
          <button className="hover:underline">Repost</button>
          <button className="hover:underline">Send</button>
        </span>
      </div>
    </div>
  );
}

export default LinkedInBigPost;
