import React from 'react';
import { useLocation } from 'react-router-dom';

function FacebookBigPost({image, caption}) {
    

    // Function to truncate the caption to 10 words
    const truncateCaption = (text = '', maxWords) => {
        const words = text.split(' ');
        if (words.length > maxWords) {
            return words.slice(0, maxWords).join(' ') + '...';
        }
        return text;
    };
  return (
    <div className="max-w-lg mx-auto bg-white border rounded-lg shadow-md p-4 mt-2">
      <div className="flex items-center mb-4">
        <img
          className="w-12 h-12 rounded-full"
          src="https://via.placeholder.com/150"
          alt="Profile"
        />
        <div className="ml-3">
          <div className="flex items-center">
            <span className="font-bold text-gray-800">Silly Thoughts</span>
            <span className="text-gray-500 ml-2">25 minutes ago</span>
          </div>
          <div className="text-gray-500 text-sm">
            <span>•</span> 
            <span>Realistic Memes</span>
          </div>
        </div>
      </div>
      <p className="text-gray-800 mb-4">
      {caption}
      </p>
      <div className="">
        <img
          className="rounded-lg"
          src={image}
          alt="Mr. Bean 1"
        />
        
      </div>
      <div className="flex justify-between mt-4 text-gray-500">
        <div className="flex items-center">
          <span>♥</span>
          <span className="ml-2">55 Likes</span>
        </div>
        <span>1 Comment</span>
      </div>
      <div className="mt-4 border-t pt-2">
        <div className="flex justify-around text-gray-600">
          <button className="flex items-center space-x-2 hover:text-blue-500">
            <i className="far fa-thumbs-up"></i>
            <span>Like</span>
          </button>
          <button className="flex items-center space-x-2 hover:text-blue-500">
            <i className="far fa-comment"></i>
            <span>Comment</span>
          </button>
          <button className="flex items-center space-x-2 hover:text-blue-500">
            <i className="far fa-share-square"></i>
            <span>Share</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default FacebookBigPost;
