import React, { useState, useCallback } from 'react';
import useAnalytics from '../hooks/useAnalytics';
import { FaPaperPlane, FaCopy, FaDownload, FaMicrophone, FaPlus, FaSearch, FaBars, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import AnimatedText from '../Components/AnimatedText';
import { Image } from 'react-bootstrap';
import avatarIcon from "../LandingPage/assets/images/1.jpg";

const IdeaLab = () => {
  const { logCustomEvent } = useAnalytics();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false); // Sidebar toggle state

  const handleSendMessage = useCallback(async (e) => {
    e.preventDefault();
    if (!inputValue.trim() || loading) return;

    logCustomEvent('idea_lab_message_sent', { message_length: inputValue.length });

    const userMessage = { type: 'user', text: inputValue };
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInputValue('');
    setLoading(true);

    try {
      const { data } = await axios.post('https://onetappost.com/api/chatCompletion', {
        prompt: inputValue,
        system_prompt: 'Idea Lab Chatbot',
      });

      setMessages(prevMessages => [...prevMessages, { type: 'ai', text: data.data }]);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => [...prevMessages, {
        type: 'ai',
        text: 'Sorry, something went wrong. Please try again later.'
      }]);
    } finally {
      setLoading(false);
    }
  }, [inputValue, loading]);

  const handleCopy = useCallback((text) => {
    navigator.clipboard.writeText(text);
    alert('Message copied to clipboard!');
  }, []);

  const renderMessages = () => (
    messages.map((msg, index) => (
      <div
        key={index}
        className={`flex ${msg.type === 'ai' ? 'justify-start' : 'justify-end'} mb-4`}
      >
        <div
          className={`p-4 rounded-lg max-w-full ${msg.type === 'ai'
            ? 'bg-indigo-100 text-gray-700 w-full md:max-w-md'
            : 'bg-indigo-500 text-white w-auto'
            }`}
          style={{ wordWrap: 'break-word' }}
        >
          {msg.type === 'ai' ? (
            <>
              <AnimatedText text={msg.text} />
              <button
                className="mt-2 flex items-center text-gray-500 text-sm hover:text-gray-700"
                onClick={() => handleCopy(msg.text)}
              >
                <FaCopy className="mr-1" /> Copy
              </button>
            </>
          ) : (
            <p>{msg.text}</p>
          )}
        </div>
      </div>
    ))
  );

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar Toggle Button for mobile */}

      <button
        className="fixed  z-50  p-2 bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white rounded-md lg:hidden"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        {sidebarOpen ? <FaTimes /> : <FaBars />}
      </button>

      {/* Sidebar */}
      <div
        className={`fixed lg:static z-40 top-0 left-0 w-64 h-full bg-white p-4 border-r border-gray-200 flex flex-col justify-between shadow-lg rounded-l-lg transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'
          } transition-transform duration-300 lg:translate-x-0`}
      >
        <div>
          <div className="flex items-center gap-2 mb-4">
            <input
              type="text"
              placeholder="Search Conversations..."
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 text-gray-500 text-sm"
            />
            <FaSearch className="text-gray-500" />
          </div>
          <div className="flex flex-col gap-3 overflow-y-auto max-h-[calc(100vh-200px)]">
            {['15 hours ago', '2 months ago', '6 months ago', '9 months ago', '11 months ago'].map((time, index) => (
              <div key={index} className="p-3 bg-gray-50 rounded-md shadow hover:bg-gray-100 cursor-pointer transition-colors">
                <p className="text-black-600 font-semibold text-sm">AI Social Media Copy</p>
                <span className="text-sm text-gray-500">{time}</span>
              </div>
            ))}
          </div>
        </div>
        <button className="w-full mt-4 py-2 flex items-center justify-center bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white rounded-md hover:opacity-90 transition-opacity shadow-lg">
          <FaPlus className="mr-2" />
          New Conversation
        </button>
      </div>

      {/* Chat Window */}
      <div className="flex-1 flex flex-col bg-white shadow-lg rounded-lg lg:rounded-r-lg ">
        {/* Chat Header */}
        <div className="flex items-center justify-between p-4 border-b border-gray-200 shadow-sm">
          <div className="flex items-center gap-3">
            <Image src={avatarIcon} alt="logo" width="50" height="50" className="rounded-full shadow" />
            <div>
              <h6 className="font-bold text-gradient bg-gradient-to-r from-[#16B197] to-[#2091DC] text-transparent bg-clip-text">
                Inspire & Optimize
              </h6>
              <span className="text-sm text-gray-500 italic">Your AI-powered content partner</span>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <button className="p-2 hover:bg-gray-100 rounded-full shadow transition-colors">
              <FaCopy className="text-gray-500" />
            </button>
            <button className="p-2 hover:bg-gray-100 rounded-full shadow transition-colors">
              <FaDownload className="text-gray-500" />
            </button>
          </div>
        </div>

        {/* Messages */}
        <div className="flex-1 overflow-y-auto p-4 bg-gray-50 rounded-b-lg">
          {messages.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full text-gray-500">
              <p>Start a conversation to get ideas!</p>
              <div className="flex flex-col mt-6 space-y-2">
                {['How can I improve my social media presence?', 'Can you help me generate content ideas?', 'What are some tips for boosting engagement?', 'Tell me more about optimizing my content strategy.'].map((question, index) => (
                  <button
                    key={index}
                    className="py-2 px-4 bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white rounded-full shadow hover:opacity-90 transition-opacity"
                    onClick={() => setInputValue(question)}
                  >
                    {question}
                  </button>
                ))}
              </div>
            </div>
          ) : (
            renderMessages()
          )}
          {loading && (
            <div className="flex justify-start mb-4">
              <div className="p-4 rounded-lg max-w-md bg-indigo-100 text-gray-700 shadow">Typing...</div>
            </div>
          )}

        </div>

        {/* Input Form */}
        <form onSubmit={handleSendMessage} className="flex items-center p-4 border-t border-gray-200 shadow-sm bg-gray-100 rounded-t-lg">
          <button type="button" className="p-2 hover:bg-gray-200 rounded-full shadow transition-colors">
            <FaMicrophone className="text-gray-500" />
          </button>
          <input
            type="text"
            placeholder="Type your message..."
            className="flex-1 mx-2 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 shadow"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            disabled={loading}
          />
          <button
            type="submit"
            className="px-4 py-2 bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white rounded-md hover:opacity-90 transition-opacity shadow-lg"
            disabled={loading}
          >
            <FaPaperPlane />
          </button>
        </form>
      </div>
    </div>
  );
};

export default IdeaLab;
