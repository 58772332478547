import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DateTimePicker from '../Components/DateAndTime';
import { FaExternalLinkAlt, FaUserCircle, FaDownload } from 'react-icons/fa';
import { ConfettiSideCannons } from '../Components/SideCanonsConfetti';
import axios from 'axios';
import '../App.css';

const PostEditor = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { date, image: initialImage, caption: initialCaption, id } = location.state || {};

  const [input, setInput] = useState(initialCaption || '');
  const [isCalendar, setIsCalendar] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(date ? new Date(date) : null);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [image, setImage] = useState(initialImage || null);
  const confettiRef = useRef(null);
  const [isSpinning, setIsSpinning] = useState(false);
  const [loading, setLoading] = useState(false); // Loader state

  useEffect(() => {
    if (id) {
      console.log('Editing post with id:', id);
    }
  }, [id]);

  const handleScheduleClick = () => {
    setIsCalendar(!isCalendar);
  };

  const handleEdit = (e) => {
    setInput(e.target.value);
  };

  const handleDateTimeSave = (date) => {
    setScheduleDate(date);
    setIsCalendar(false);
  };

  const handleViewEventClick = () => {
    sendScheduledData();
    if (confettiRef.current) {
      confettiRef.current.triggerConfetti();
    }
    setIsCardVisible(true);
  };

  const closeCard = () => {
    setIsCardVisible(false);
  };

  const sendScheduledData = async () => {
    console.log('Scheduled Data:', {
      image,
      caption: input,
      date: scheduleDate,
      platform: ["instagram"],
    });

    const data = await fetch('https://onetappost.com/api/saveGeneratedPost', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('ai-tk')}`,
      },
      body: JSON.stringify({
        image: image[0],
        caption: input,
        date: scheduleDate,
        platform: ["instagram"],
      }),
    });

    if (data.ok) {
      // navigate('/scheduler')
    } else {
      console.log('Post not scheduled');
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
  };

  const handleGenerateCaption = async () => {
    setLoading(true); // Start loader
    try {
      const response = await axios.post('https://onetappost.com/api/chatCompletion', {
        prompt: input + " write only one caption along the lines of the provided one with hashtags. The caption should be different from previous ones and creative. Just send the caption, nothing else. Reply in the same language and script. Add relevant emojis",
      });

      const generatedCaption = response.data.data.trim();
      setInput(generatedCaption);
    } catch (error) {
      console.error('Error generating caption:', error);
    } finally {
      setLoading(false); // End loader
    }
  };

  const handleDownloadImage = () => {
    if (image) {
      const link = document.createElement('a');
      link.href = image;
      link.download = 'preview_image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-md max-w-5xl mx-auto">
      <div className="flex flex-col md:flex-row justify-between items-start gap-6">
        <div className="w-full md:w-2/3 space-y-4">
          <div className="flex justify-between items-center">
            <label className="text-gray-700 text-sm font-bold">
              Publish to
            </label>
            <button
              className="text-[#4fe9b3] font-semibold flex items-center"
              onClick={() => navigate('/profile')}
            >
              Connect Profiles
              <FaExternalLinkAlt className="ml-1" />
            </button>
          </div>
          <select className="w-full border rounded-md p-2 text-gray-700">
            <option>Select social profiles (4)</option>
          </select>
          <textarea
            className="w-full border rounded-md p-2 text-gray-700 resize-none"
            rows="4"
            value={input}
            onChange={handleEdit}
            placeholder="Enter your caption here..."
          />
          <div
  className={`hover-container cursor-pointer transition-transform duration-300 ${isSpinning ? 'rotate-180' : ''}`}
  onClick={handleGenerateCaption}
>
  {loading ? ( // Show the loader while generating the caption
    <div className="loader"></div> // Use the loader CSS here
  ) : (
    <img
      src="https://1000logos.net/wp-content/uploads/2023/02/ChatGPT-Logo.png"
      className="w-14 h-8"
      alt="Generate Caption"
    />
  )}
</div>

          <div className="flex items-center space-x-4">
            <label className="cursor-pointer">
              <input type="file" className="hidden" onChange={handleImageChange} accept="image/*" />
              <div className="border-2 border-dashed border-gray-300 bg-gray-100 rounded-md p-2 flex items-center justify-center w-40 h-40 hover:bg-gray-200 transition-colors">
                <span className="text-gray-500 text-5xl">+</span>
              </div>
            </label>
            <div className="flex-1">
              {image && (
                <img src={image} alt="Uploaded" className="w-40 h-40 rounded-md object-cover" />
              )}
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/3">
          <div className="border bg-[#EFF3F6] rounded-md p-4 flex flex-col justify-between h-full">
            <div>
              <label className="block font-poppins text-sm font-bold mb-2">
                Preview
              </label>
              <div className="bg-white p-3 rounded-md overflow-hidden shadow-sm">
                <div className="flex items-center mb-4">
                  <FaUserCircle className="text-gray-400" size={24} />
                  <span className="ml-4 flex-1 h-5 bg-gray-300 rounded-md"></span>
                </div>
                {image && (
                  <div className="relative">
                    <img src={image} alt="preview" className="w-full h-48 object-cover rounded-md" />
                    <button
                      onClick={handleDownloadImage}
                      className="absolute top-2 right-2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 transition-colors"
                    >
                      <FaDownload className="text-gray-600" />
                    </button>
                  </div>
                )}
                <p className="mt-2 text-sm text-gray-700">{input}</p>
              </div>
            </div>

            <div className="mt-4 flex flex-col space-y-2">
              <button
                className="bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white rounded-md px-4 py-2 hover:opacity-90 transition-opacity"
                onClick={handleScheduleClick}
              >
                Schedule for later
              </button>
              <ConfettiSideCannons ref={confettiRef} />
              <button
                className="bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white rounded-md px-4 py-2 hover:opacity-90 transition-opacity"
                onClick={handleViewEventClick}
              >
                Post now
              </button>
            </div>
          </div>
        </div>
      </div>

      {isCalendar && (
        <div className="fixed z-20 inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <DateTimePicker
            onClose={() => setIsCalendar(false)}
            onSave={handleDateTimeSave}
          />
        </div>
      )}

      {isCardVisible && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Success</h2>
            <p>Your Post has been Published Successfully 💐</p>
            <button
              className="mt-4 px-4 py-2 bg-gradient-to-r from-[#2091DC] to-[#16B197] text-white rounded-lg hover:opacity-90 transition-opacity"
              onClick={closeCard}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostEditor;
