import { Link, useNavigate, useLocation } from 'react-router-dom';
import logoImg from '../LandingPage/assets/images/logosaas.png';
import Image from 'react-bootstrap/Image';
import { ReactComponent as MenuIcon } from '../LandingPage/assets/icons/menu.svg';

export const Navbar = () => {

    // scrollToSection
    const scrollToSection = (sectionId) => {
        navigate(`/${sectionId}`);
    };

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='bg-black text-white'>
            <div className="container">
                <div className='py-4 flex items-center justify-between'>
                    <div className='relative'>
                        <div className='absolute w-full top-0 bottom-0 bg-gradient-to-r from-pink-300 to-blue-400 blur-md'></div>
                        <Image
                            src={logoImg}
                            alt="saas logo"
                            className='w-12 h-12 relative'
                            onClick={() => navigate("/signin")}
                        />
                    </div>
                    <div className='border border-white border-opacity-25 h-10 w-10 rounded-lg inline-flex items-center justify-center sm:hidden'>
                        <MenuIcon />
                    </div>
                    <nav className='items-center gap-6 hidden sm:flex'>
                        <Link to="/faqs">FAQs</Link>
                        <Link to="/features">Features</Link>
                        <Link to="/pricing">Pricing</Link>
                        <button
                            onClick={() => navigate("/signin")}
                            className='bg-white text-black py-2 px-4 rounded-lg hover:scale-[1.03] transition'
                        >
                            Start For Free
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    );
};
