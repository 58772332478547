import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronRight, Image as ImageIcon, HelpCircle, X, Download } from 'lucide-react';
import { Tooltip } from '@mui/material';

export default function TextToImage() {
    const [generalSettingsOpen, setGeneralSettingsOpen] = useState(true);
    const [selectedModel, setSelectedModel] = useState('Flux');
    const [selectedStyle, setSelectedStyle] = useState('No style');
    const [imageCount, setImageCount] = useState(1);
    const [aspectRatio, setAspectRatio] = useState('4:5');
    const [prompt, setPrompt] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [generatedImages, setGeneratedImages] = useState([]);
    const [fullScreenImage, setFullScreenImage] = useState(null);
    const [historyImages, setHistoryImages] = useState([]);

    const models = ['Flux', 'Flux Dev', 'Realistic', 'Anime'];
    const styles = ['No style', 'Icon', 'Logo', 'Sketch', 'Watercolor', '3D'];
    const aspectRatios = ['1:1', '4:5', '16:9', '9:16'];

    const handleGenerate = async () => {
        setIsGenerating(true);
        const input = {
            prompt: `${prompt} ${selectedStyle}`,
            negative_prompt: "",
            num_outputs: imageCount,
            aspect_ratio: aspectRatio,
        };

        try {
            const response = await fetch('https://ai.reelsbanao.com/api/generate-image', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(input),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log('Images generated successfully');

            const data = await response.json();
            setGeneratedImages(data.imageUrl);
        } catch (error) {
            console.error('Error generating images:', error);
            // You might want to set an error state here to display to the user
        } finally {
            setIsGenerating(false);
        }
    };

    const handleImageClick = (image) => {
        setFullScreenImage(image);
    };

    const handleCloseFullScreen = () => {
        setFullScreenImage(null);
    };

    const handleDownload = (imageUrl) => {
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'generated-image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        fetchHistoryImages();
    }, []);

    const fetchHistoryImages = async () => {
        try {
            const response = await fetch("https://photoai.com/load_photos?action=get-camera-roll");
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setHistoryImages(data);
        } catch (error) {
            console.error("Error fetching history images:", error);
        }
    };

    return (
        <div>
            <div className="md:flex h-screen bg-[#F8F9FA]">
                {/* Sidebar */}
                <div className="w-80 bg-white p-4 overflow-y-auto shadow-md">
                    <h2 className="text-2xl font-bold mb-4 text-black">Image Generation</h2>
                    <div className="space-y-6">
                        {/* General Settings */}
                        <div>
                            <button
                                className="flex items-center justify-between w-full text-left font-semibold text-base bg-[#F0F3F3] p-2 rounded-lg hover:bg-gray-200 transition-colors"
                                onClick={() => setGeneralSettingsOpen(!generalSettingsOpen)}
                            >
                                General Settings
                                {generalSettingsOpen ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
                            </button>
                            {generalSettingsOpen && (
                                <div className="mt-3 space-y-6">
                                    {/* Models */}
                                    <div>
                                        <div className="flex items-center mb-2">
                                            <h3 className="font-medium text-sm">AI Models</h3>
                                            <Tooltip title="Choose the AI model that best fits your image generation needs" arrow>
                                                <HelpCircle className="h-4 w-4 ml-1 text-gray-400 cursor-help" />
                                            </Tooltip>
                                        </div>
                                        <div className="flex flex-wrap gap-2">
                                            {models.map((model) => (
                                                <button
                                                    key={model}
                                                    className={`px-3 py-1 text-xs rounded-full transition-colors ${selectedModel === model
                                                        ? "bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white"
                                                        : "bg-[#F0F3F3] text-gray-700 hover:bg-gray-300"
                                                        }`}
                                                    onClick={() => setSelectedModel(model)}
                                                >
                                                    {model}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    {/* Styles */}
                                    <div>
                                        <div className="flex items-center justify-between mb-2">
                                            <div className="flex items-center">
                                                <h3 className="font-medium text-sm">Image Styles</h3>
                                                <Tooltip title="Select a style to influence the aesthetic of your generated image" arrow>
                                                    <HelpCircle className="h-4 w-4 ml-1 text-gray-400 cursor-help" />
                                                </Tooltip>
                                            </div>
                                            <button className="text-[#16B197] hover:underline text-xs">See All Styles</button>
                                        </div>
                                        <div className="grid grid-cols-3 gap-2">
                                            {styles.map((style) => (
                                                <button
                                                    key={style}
                                                    className={`h-16 rounded-lg border-2 transition-colors ${selectedStyle === style
                                                        ? 'border-[#16B197] bg-[#F0F3F3]'
                                                        : 'border-gray-200 hover:border-gray-300'
                                                        }`}
                                                    onClick={() => setSelectedStyle(style)}
                                                >
                                                    {style === 'No style' ? (
                                                        <ImageIcon className="h-6 w-6 text-gray-400 mx-auto" />
                                                    ) : (
                                                        <div className="w-full h-full bg-white rounded flex items-center justify-center text-xs">
                                                            {style}
                                                        </div>
                                                    )}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    {/* Number of Images */}
                                    <div>
                                        <h3 className="font-medium text-sm mb-2">Number of Images</h3>
                                        <div className="flex gap-2">
                                            {[1, 2, 3, 4].map((num) => (
                                                <button
                                                    key={num}
                                                    className={`flex-1 py-1 text-sm rounded-lg transition-colors ${imageCount === num
                                                        ? "bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white"
                                                        : "bg-[#F0F3F3] text-gray-700 hover:bg-gray-300"
                                                        }`}
                                                    onClick={() => setImageCount(num)}
                                                >
                                                    {num}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    {/* Aspect Ratio */}
                                    <div>
                                        <div className="flex items-center mb-2">
                                            <h3 className="font-medium text-sm">Aspect Ratio</h3>
                                            <Tooltip title="Choose the dimensions for your generated image" arrow>
                                                <HelpCircle className="h-4 w-4 ml-1 text-gray-400 cursor-help" />
                                            </Tooltip>
                                        </div>
                                        <div className="grid grid-cols-2 gap-2">
                                            {aspectRatios.map((ratio) => (
                                                <button
                                                    key={ratio}
                                                    className={`py-1 text-sm rounded-lg transition-colors ${aspectRatio === ratio
                                                        ? "bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white"
                                                        : "bg-[#F0F3F3] text-gray-700 hover:bg-gray-300"
                                                        }`}
                                                    onClick={() => setAspectRatio(ratio)}
                                                >
                                                    {ratio}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* Main Content */}
                <div className="md:flex-1  md:px-4 md:flex md:flex-col md:overflow-hidden">
                    <div className="md:flex-1   bg-white rounded-lg shadow-lg mb-4 md:overflow-auto">
                        {generatedImages.length > 0 ? (
                            <div className={`grid grid-cols-2 sm:grid-cols-${Math.min(generatedImages.length, 4)} gap-2 p-2`}>
                                {generatedImages.map((image, index) => (
                                    <div key={index} className="aspect-w-1 aspect-h-1">
                                        <img
                                            src={image}
                                            alt={`Generated image ${index + 1}`}
                                            className="w-full h-full object-cover rounded-lg cursor-pointer"
                                            onClick={() => handleImageClick(image)}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className=" md:w-full md:h-full flex items-center justify-center">
                                <iframe
                                    className="md:w-full md:h-full w-6/12 h-2/5"
                                    src="https://lottie.host/embed/69d58130-df4c-49c4-9047-48ba1aacb097/xFGDHKOb2p.json"
                                    title="AI Image Generation Animation"
                                ></iframe>
                            </div>
                        )}
                    </div>
                    <div className=" mb-10 md:mb-0 bg-white rounded-lg p-4 shadow-lg">
                        <textarea
                            className="w-full p-3 mb-3 text-sm border rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-[#16B197]"
                            rows="2"
                            placeholder='Describe your image. For example: "A cat flying in the sky wearing a superman suit"'
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                        />
                        <button
                            className="w-full bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white py-2 rounded-lg text-sm font-semibold hover:opacity-90 transition-opacity disabled:opacity-50"
                            onClick={handleGenerate}
                            disabled={isGenerating || !prompt.trim()}
                        >
                            {isGenerating ? 'Generating...' : 'Generate Image'}
                        </button>
                    </div>

                    {/* History Section */}

                </div>



                {/* Full Screen Image */}
                {fullScreenImage && (
                    <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
                        <button
                            className="absolute top-4 right-4 text-white hover:text-gray-300"
                            onClick={handleCloseFullScreen}
                        >
                            <X className="h-8 w-8" />
                        </button>
                        <button
                            className="absolute top-4 left-4 text-white hover:text-gray-300"
                            onClick={() => handleDownload(fullScreenImage)}
                        >
                            <Download className="h-8 w-8" />
                        </button>
                        <img
                            src={fullScreenImage}
                            alt="Full screen image"
                            className="max-w-full max-h-full object-contain"
                        />
                    </div>
                )}


            </div>
            <div className="mt-96 md:mt-0 bg-white rounded-lg p-4 md:shadow-lg md:overflow-auto">
                <h3 className="text-xl font-bold mb-4">Generation History</h3>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                    {historyImages.map((image) => (
                        <div key={image.slug} className="aspect-w-1 aspect-h-1">
                            <img
                                src={image.photo_url}
                                alt={`Generated image ${image.slug}`}
                                className="w-full h-full object-cover rounded-lg cursor-pointer"
                                onClick={() => handleImageClick(image.photo_url)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}