import React from 'react';
import { useLocation } from 'react-router-dom';

function PinterestPost() {
    const location = useLocation();
    const { image, caption } = location.state || {};

    // Function to truncate the caption to 10 words
    const truncateCaption = (text = '', maxWords) => {
        const words = text.split(' ');
        if (words.length > maxWords) {
            return words.slice(0, maxWords).join(' ') + '...';
        }
        return text;
    };

  return (
    <div className="w-52 h-52 bg-white border rounded-lg shadow-md p-2 hover:scale-105 transition-all cursor-pointer">
      <img
        className="w-full h-32 rounded-lg mb-1"
        src={image}
        alt="Pinterest Post"
      />
      <div className="text-xs font-bold text-gray-800 mb-1"> {truncateCaption(caption, 10)}</div>
      <div className="text-xs text-gray-500 ">#fashion #aesthetic #hugs</div>
      <div className="text-xs text-gray-600 ">Reese • 159 followers</div>
    </div>
  );
}

export default PinterestPost;
