import React from 'react';
import { FaQuestionCircle, FaEnvelope, FaPhoneAlt, FaBook } from 'react-icons/fa';

const HelpPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl w-full bg-white shadow-md rounded-lg p-8">
        <h1 className="text-2xl font-semibold text-[#414962] font-poppins mb-6">Help Center</h1>

        <div className="space-y-6">
          {/* FAQ Section */}
          <section>
            <h2 className="text-xl font-semibold text-[#414962] font-poppins mb-4 flex items-center">
              <FaQuestionCircle className="mr-2 text-[#2091DC]" /> Frequently Asked Questions
            </h2>
            <div className="bg-[#E8F0FF] rounded-lg p-4">
              <h3 className="text-lg font-medium text-[#414962] mb-2">How do I create a new post?</h3>
              <p className="text-[#414962]">
                To create a new post, navigate to the "Create Post" section in the sidebar and follow the instructions provided. You can add text, images, and schedule your post for later.
              </p>
            </div>
            <div className="bg-[#E8F0FF] rounded-lg p-4 mt-4">
              <h3 className="text-lg font-medium text-[#414962] mb-2">How can I manage my content calendar?</h3>
              <p className="text-[#414962]">
                Go to the "Content Calendar" section to view, edit, and manage all your scheduled posts in one place. You can drag and drop posts to reschedule them easily.
              </p>
            </div>
          </section>

          {/* Contact Support Section */}
          <section>
            <h2 className="text-xl font-semibold text-[#414962] font-poppins mb-4 flex items-center">
              <FaEnvelope className="mr-2 text-[#2091DC]" /> Contact Support
            </h2>
            <div className="bg-[#E8F0FF] rounded-lg p-4">
              <h3 className="text-lg font-medium text-[#414962] mb-2">Email Us</h3>
              <p className="text-[#414962]">
                For any queries or issues, you can reach out to us at <a href="mailto:support@socialai.com" className="text-[#2091DC]">support@socialai.com</a>. Our support team will respond within 24 hours.
              </p>
            </div>
            <div className="bg-[#E8F0FF] rounded-lg p-4 mt-4">
              <h3 className="text-lg font-medium text-[#414962] mb-2">Call Us</h3>
              <p className="text-[#414962]">
                If you need immediate assistance, feel free to call us at <a href="tel:+123456789" className="text-[#2091DC]">+123 456 789</a>. Our phone support is available from 9 AM to 6 PM, Monday to Friday.
              </p>
            </div>
          </section>

          {/* User Guide Section */}
          <section>
            <h2 className="text-xl font-semibold text-[#414962] font-poppins mb-4 flex items-center">
              <FaBook className="mr-2 text-[#2091DC]" /> User Guide
            </h2>
            <div className="bg-[#E8F0FF] rounded-lg p-4">
              <p className="text-[#414962]">
                Need more detailed instructions? Download our comprehensive <a href="/user-guide.pdf" className="text-[#2091DC]">User Guide</a> that covers all features of OneTap Post in detail.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HelpPage;
