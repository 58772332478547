import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../api/AuthContext';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import the icons
import register from '../api/auth';
import useAnalytics from '../hooks/useAnalytics';

const Signup = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const { logCustomEvent } = useAnalytics();  // Move this to the top level
  const [inputData, setInputData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
  const [selectedUserType, setSelectedUserType] = useState(''); // Add this state

  useEffect(() => {
    if (!loading && user) {
      navigate('/dashboard', { replace: true });
    }
  }, [user, loading, navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = async () => {
    const schema = Yup.object().shape({
      name: Yup.string().required('Name required.').min(2, 'Name must be 2 characters or more.'),
      email: Yup.string().email('Invalid email address.').required('Email required.'),
      password: Yup.string().required('Password required.').min(6, 'Password must be 6 characters or more.'),
      confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match.')
    });

    try {
      await schema.validate(inputData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleData = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const sendForm = async (e) => {
    e.preventDefault();

    const isValid = await validateForm();
    if (isValid) {
      setButtonLoading(true);
      console.log('Submit button clicked');
      try {
        const response = await register(inputData);
        localStorage.setItem("ai-tk", response.data.data.token);
        toast.success(response.data.message);
        setButtonLoading(false);
        navigate('/dashboard', { replace: true });
      } catch (error) {
        toast.error(error.message || 'Something went wrong!');
        setButtonLoading(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    // ... existing code ...
    logCustomEvent('user_signed_up', {
      signup_method: 'email',
      user_type: selectedUserType
    });
    // ... rest of the function ...
  };

  const handleGoogleSignup = () => {
    logCustomEvent('user_signed_up', {
      signup_method: 'google'
    });
    // ... rest of the function ...
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ClipLoader color="#2130B7" loading={loading} size={60} />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-6 sm:px-4 lg:px-8">
      <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-2xl font-bold text-center mb-6 text-gray-800">Sign up for a Free Account</h1>
        <p className="text-center text-gray-600 mb-6">Try for free! No credit card required.</p>

        <div className="bg-[#FCF4DB] border-l-4 border-yellow-500 text-yellow-700 p-4 rounded mb-6">
          <p className="font-semibold text-black text-xl">
            <span role="img" aria-label="star" className='text-[#973D81]'>⭐</span> Save 50% on Annual Plans
          </p>
          <p className='text-black ml-10'>on Sign up using business email</p>
        </div>

        <form onSubmit={sendForm} className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
            <input
              id="name"
              type="text"
              name="name"
              placeholder='Type your full name'
              value={inputData.name}
              onChange={handleData}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              aria-invalid={!!errors.name}
              aria-describedby="name-error"
            />
            {errors.name && <p id="name-error" className="mt-2 text-sm text-red-600">{errors.name}</p>}
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
            <input
              id="email"
              type="email"
              name="email"
              placeholder='Type your email'
              value={inputData.email}
              onChange={handleData}
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              aria-invalid={!!errors.email}
              aria-describedby="email-error"
            />
            {errors.email && <p id="email-error" className="mt-2 text-sm text-red-600">{errors.email}</p>}
          </div>

          <div className="relative">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
            <div className="relative">
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Create a Password"
                value={inputData.password}
                onChange={handleData}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm pr-10"
                aria-invalid={!!errors.password}
                aria-describedby="password-error"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            {errors.password && <p id="password-error" className="mt-2 text-sm text-red-600">{errors.password}</p>}
          </div>

          <button
            type="submit"
            className="w-full mt-6 py-2 flex items-center justify-center bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white rounded-md hover:bg-gradient-to-r hover:from-[#16B197] hover:to-[#2091DC] shadow-lg"
            disabled={buttonLoading}
          >
            {buttonLoading ? <ClipLoader color="#ffffff" loading={buttonLoading} size={20} /> : 'Sign Up'}
          </button>
        </form>

        <div className="relative flex py-4 items-center">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="flex-shrink mx-4 text-gray-500">Or continue with</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>

        <div className="space-y-4">
          <button className="w-full flex items-center justify-center py-2 border border-black cursor-pointer hover:bg-gray-100 bg-white text-black font-semibold rounded-md">
            <img src='/google-logo-NePEveMl.svg' alt='google' className="w-5 h-5 mr-2" />
            Continue with Google
          </button>
        </div>

        <p className="text-center text-gray-500 text-sm mt-6">
          By continuing, you agree to OneTapPost <a href="/terms-of-service" className="text-blue-500 underline">Terms of Service</a> and <a href="/privacy-policy" className="text-blue-500 underline">Privacy Policy</a>.
        </p>

        <p className="text-center text-sm text-gray-500 mt-4">
          Already have an account? <Link to='/signin' className="text-blue-500 underline">Sign in</Link>
        </p>
      </div>
    </div>
  );
};

export default Signup;
