import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
            <p className="mb-4">
                Welcome to OneTap Post. This Privacy Policy explains how we collect, use, and protect your
                information when you use our platform, located at https://onetappost.com (the "Site").
                By accessing or using the Site, you agree to the terms of this Privacy Policy.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">1. Information We Collect</h2>
            <p className="mb-4">
                When you use OneTap Post, we may collect the following types of information:
            </p>
            <ul className="list-disc pl-6 mb-4">
                <li className="mb-2">
                    <strong>Personal Information:</strong> This includes your name, email address, and any
                    other information you provide when you sign up for an account or contact us.
                </li>
                <li className="mb-2">
                    <strong>Social Media Account Information:</strong> When you connect your social media
                    accounts to our platform, we may collect metadata such as your account username, profile
                    picture, and social media posts.
                </li>
                <li className="mb-2">
                    <strong>Usage Data:</strong> We collect information on how you interact with our platform,
                    such as the pages you visit, the features you use, and the time spent on the site.
                </li>
                <li className="mb-2">
                    <strong>Device Information:</strong> We may collect information about the device you use to access our service, including the hardware model, operating system, unique device identifiers, and mobile network information.
                </li>
                <li className="mb-2">
                    <strong>Cookies and Similar Technologies:</strong> We use cookies, web beacons, and similar technologies to improve your experience on our site, analyze usage patterns, and customize content.
                </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-3">2. How We Use Your Information</h2>
            <p className="mb-4">
                We use your information to provide, maintain, and improve our services, including:
            </p>
            <ul className="list-disc pl-6 mb-4">
                <li className="mb-2">To manage and schedule posts across your connected social media accounts.</li>
                <li className="mb-2">To offer AI-generated content suggestions based on your preferences and usage.</li>
                <li className="mb-2">To analyze usage trends and improve the functionality of the platform.</li>
                <li className="mb-2">To communicate with you, such as sending important updates or responding to inquiries.</li>
                <li className="mb-2">To detect, prevent, and address technical issues or fraudulent activities.</li>
                <li className="mb-2">To comply with legal obligations and enforce our terms of service.</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-3">3. How We Protect Your Information</h2>
            <p className="mb-4">
                We take the security of your personal information seriously and implement industry-standard
                measures to protect your data, including:
            </p>
            <ul className="list-disc pl-6 mb-4">
                <li className="mb-2">Encryption of data in transit and at rest</li>
                <li className="mb-2">Regular security audits and vulnerability assessments</li>
                <li className="mb-2">Access controls and authentication mechanisms</li>
                <li className="mb-2">Employee training on data protection and privacy practices</li>
            </ul>
            <p className="mb-4">
                However, please note that no method of transmission over the internet or method of electronic storage
                is 100% secure, so we cannot guarantee absolute security.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">4. Sharing Your Information</h2>
            <p className="mb-4">
                We do not sell, trade, or otherwise transfer your personal information to third parties
                without your consent, except in the following cases:
            </p>
            <ul className="list-disc pl-6 mb-4">
                <li className="mb-2">To comply with legal obligations or protect our legal rights.</li>
                <li className="mb-2">
                    To trusted third-party service providers who assist us in operating our platform,
                    conducting our business, or serving our users, so long as they agree to keep your
                    information confidential.
                </li>
                <li className="mb-2">
                    Aggregated and anonymized data may be shared for research, analytics, or marketing
                    purposes that cannot be used to identify you.
                </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-3">5. Your Rights and Choices</h2>
            <p className="mb-4">
                You have certain rights regarding your personal information, including:
            </p>
            <ul className="list-disc pl-6 mb-4">
                <li className="mb-2">The right to access and receive a copy of your personal data</li>
                <li className="mb-2">The right to correct or update your personal information</li>
                <li className="mb-2">The right to request deletion of your personal data</li>
                <li className="mb-2">The right to object to or restrict certain processing of your data</li>
                <li className="mb-2">The right to data portability</li>
            </ul>
            <p className="mb-4">
                To exercise these rights or for any privacy-related inquiries, please contact us at{" "}
                <a href="mailto:privacy@onetappost.com" className="text-blue-600 hover:underline">
                    privacy@onetappost.com
                </a>
                .
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">6. Changes to Our Privacy Policy</h2>
            <p className="mb-4">
                We may update this Privacy Policy from time to time. Any changes will be posted on this
                page, and the updated version will be effective as of the date specified at the top of the
                page. We encourage you to review this Privacy Policy periodically for any changes.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">7. Contact Us</h2>
            <p className="mb-4">
                If you have any questions about this Privacy Policy or how we handle your data, please
                contact us at:
            </p>
            <p className="mb-4">
                Email:{" "}
                <a href="mailto:info@onetappost.com" className="text-blue-600 hover:underline">
                    info@onetappost.com
                </a>
            </p>
            <p className="mb-4">
                Address: New Delhi, India
            </p>
        </div>
    );
};

export default PrivacyPolicy;
