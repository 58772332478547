
import React from 'react';
import { FaHeart, FaRegComment, FaShareSquare, FaBookmark } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

function InstagramPost() {
    const location = useLocation();
    const { image, caption } = location.state || {};

    // Function to truncate the caption to 10 words
    const truncateCaption = (text = '', maxWords) => {
        const words = text.split(' ');
        if (words.length > maxWords) {
            return words.slice(0, maxWords).join(' ') + '...';
        }
        return text;
    };
  return (
    <div className="w-52 h-52 bg-white border rounded-lg shadow-md p-2 hover:scale-105 transition-all cursor-pointer">
      <img
        className="w-full h-28 rounded-lg mb-1"
        src={image}
        alt="Instagram Post"
      />
      <div className="text-xs text-gray-800 mb-1">{truncateCaption(caption, 10)}</div>
      <div className="flex justify-between items-center text-xs text-gray-800">
        <FaHeart className="w-4 h-4" />
        <FaRegComment className="w-4 h-4" />
        <FaShareSquare className="w-4 h-4" />
        <FaBookmark className="w-4 h-4" />
      </div>
      <div className="text-xs text-gray-600 mt-1">10,241 likes</div>
      {/* <div className="text-xs text-gray-500 mt-1">View all 26 comments</div> */}
    </div>
  );
}

export default InstagramPost;
