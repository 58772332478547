import React, { useEffect, useRef } from 'react';
import { motion, useMotionTemplate, useMotionValue } from 'framer-motion';

export const PricingCard = ({ plan, price, features, isRecommended }) => {
    const offsetX = useMotionValue(-100);
    const offsetY = useMotionValue(-100);
    const maskImage = useMotionTemplate`radial-gradient(120px 120px at ${offsetX}px ${offsetY}px, black, transparent)`;

    const border = useRef(null);

    useEffect(() => {
        const updateMousePosition = (e) => {
            if (!border.current) return;
            const rect = border.current.getBoundingClientRect();
            offsetX.set(e.x - rect.x);
            offsetY.set(e.y - rect.y);
        };
        window.addEventListener('mousemove', updateMousePosition);
        return () => {
            window.removeEventListener('mousemove', updateMousePosition);
        };
    }, []);

    return (
        <div className="relative bg-black border border-white/30 text-white rounded-xl p-6 sm:flex-1">
            <motion.div
                className='absolute inset-0 border-2 border-purple-400 rounded-xl'
                style={{
                    maskImage,
                    WebkitMaskImage: maskImage,
                }}
                ref={border}
            ></motion.div>
            <h3 className="text-2xl font-bold">{plan}</h3>
            <h4 className="mt-4 text-xl">
                {price === "Contact us" ? price : (
                    <>
                        <span className="line-through text-gray-400">$99</span>{" "}
                        <span className="text-green-400">{price}</span>
                    </>
                )}
            </h4>
            <ul className="mt-6 space-y-4">
                {features.map((feature, index) => (
                    <li key={index} className="flex items-center">
                        <img
                            src="https://cdn.prod.website-files.com/64fb622563d6e95bf5562e50/66bfc306940c42e138c5253c_check-bold.svg"
                            alt="check"
                            className="w-6 h-6 mr-2"
                        />
                        {feature}
                    </li>
                ))}
            </ul>
            <a
                href="#"
                className="mt-8 inline-block bg-white text-black text-center py-2 rounded-lg w-full"
            >
                {isRecommended ? "Get started for free" : "Contact Sales"}
            </a>
            {isRecommended && (
                <div className="absolute top-4 right-4 bg-[#5D2CA8] text-white text-xs font-bold px-2 py-1 rounded">
                    Recommended
                </div>
            )}
        </div>
    );
};

const PricingComponent = () => {
    const pricingPlans = [
        {
            plan: "Basic",
            price: "$25 / month",
            features: [
                "Prompt to component",
                "Unlimited generations",
                "Image to code (coming soon)",
                "Matches your design system",
                "Matches your codebase"
            ],
            isRecommended: true
        },
        {
            plan: "Pro",
            price: "Contact us",
            features: [
                "All Basic features",
                "Turn Figma designs into code",
                "Use your own design system",
                "Automated Pull Requests",
                "Design linter in Figma"
            ],
            isRecommended: false
        },
        {
            plan: "Enterprise",
            price: "Contact us",
            features: [
                "All Pro features",
                "Unlimited Projects & imports",
                "Design System sync with PRs",
                "AI-powered design linter",
                "Design System management"
            ],
            isRecommended: false
        }
    ];

    return (
        <div className="bg-gradient-to-r from-[#5D2CA8] to-black text-white py-18 sm:py-24">
            <div className="max-w-7xl mx-auto px-6">
                <h2 className="text-center font-bold text-5xl sm:text-6xl tracking-tighter mb-8">Pricing Plans</h2>
                <p className="text-center text-lg mb-12">
                    Choose the perfect plan for your design & development needs. Free trial available that includes 10 free generations per month.
                </p>
                <div className="flex flex-col lg:flex-row justify-center gap-8">
                    {pricingPlans.map((plan, index) => (
                        <PricingCard
                            key={index}
                            plan={plan.plan}
                            price={plan.price}
                            features={plan.features}
                            isRecommended={plan.isRecommended}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PricingComponent;
