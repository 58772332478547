import React from 'react';
import { FaThumbsUp, FaRegComment, FaShare } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

function FacebookPost() {
    const location = useLocation();
    const { image, caption } = location.state || {};
    const navigate = useNavigate()

    // Function to truncate the caption to 10 words
    const truncateCaption = (text = '', maxWords) => {
        const words = text.split(' ');
        if (words.length > maxWords) {
            return words.slice(0, maxWords).join(' ') + '...';
        }
        return text;
    };

    return (
        <div className="w-52 h-52 bg-white border rounded-lg shadow-md p-2 cursor-pointer hover:scale-105 transition-all" >
            <div className="flex items-center mb-1">
                <img
                    className="w-6 h-6 rounded-full"
                    src="https://via.placeholder.com/150"
                    alt="Profile"
                />
                <div className="ml-1 text-xs">
                    <div className="font-bold text-gray-800">Silly Thoughts</div>
                    <div className="text-gray-500">25 min ago</div>
                </div>
            </div>
            <p className="text-xs text-gray-800 mb-1">
                {truncateCaption(caption, 10)}
            </p>
            <img
                className="w-full h-20 rounded-lg mb-1"
                src={image}
                alt="Mr. Bean"
            />
            <div className="flex justify-between text-xs text-gray-500">
                <FaThumbsUp />
                <FaRegComment />
                <FaShare />
            </div>
        </div>
    );
}

export default FacebookPost;
