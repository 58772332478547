import React from "react";

const TermsOfService = () => {
    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
            <p className="mb-4">
                Welcome to OneTap Post. By using our services, you agree to these terms. Please read them carefully.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">1. Acceptance of Terms</h2>
            <p className="mb-4">
                By accessing or using OneTap Post, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our services.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">2. Description of Service</h2>
            <p className="mb-4">
                OneTap Post provides a platform for social media management and content creation, including AI-generated content suggestions. We reserve the right to modify, suspend, or discontinue any part of the service at any time.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">3. User Accounts</h2>
            <p className="mb-4">
                You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">4. User Content</h2>
            <p className="mb-4">
                You retain all rights to the content you post through our service. By using OneTap Post, you grant us a non-exclusive license to use, modify, and distribute your content for the purpose of providing and improving our services.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">5. Prohibited Activities</h2>
            <p className="mb-4">
                You agree not to use OneTap Post for any unlawful purpose or in any way that could damage, disable, overburden, or impair our services.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">6. Intellectual Property</h2>
            <p className="mb-4">
                The OneTap Post platform, including its original content, features, and functionality, is owned by OneTap Post and protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">7. Limitation of Liability</h2>
            <p className="mb-4">
                OneTap Post shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of or inability to use the service.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">8. Changes to Terms</h2>
            <p className="mb-4">
                We reserve the right to modify these Terms of Service at any time. We will notify users of any significant changes via email or through the platform.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">9. Governing Law</h2>
            <p className="mb-4">
                These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-3">10. Contact Us</h2>
            <p className="mb-4">
                If you have any questions about these Terms, please contact us at{" "}
                <a href="mailto:info@onetappost.com" className="text-blue-600 hover:underline">
                    info@onetappost.com
                </a>
                .
            </p>
        </div>
    );
};

export default TermsOfService;