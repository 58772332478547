import React, { useEffect, useState } from 'react';
import axiosApi from '../api/apiAxios';

function Profile({ insta_accessToken, setInsta_accessToken }) {
  const platforms = [
    { name: 'Instagram', description: 'Schedule and analyze posts on Instagram Business profiles.', icon: 'https://img.icons8.com/fluency/48/000000/instagram-new.png', buttonText: 'Connect', link: true },
    { name: 'Facebook', description: 'Schedule and analyze posts on Facebook Pages.', icon: 'https://img.icons8.com/color/48/000000/facebook-new.png', buttonText: 'Coming Soon', disabled: true },
    { name: 'X.com (Twitter)', description: 'Schedule and analyze posts on Twitter accounts.', icon: 'https://img.icons8.com/fluency/48/000000/twitter.png', buttonText: 'Coming Soon', disabled: true },
    { name: 'LinkedIn', description: 'Connect your LinkedIn Personal profiles or Company pages.', icon: 'https://img.icons8.com/fluency/48/000000/linkedin.png', buttonText: 'Coming Soon', disabled: true },
    { name: 'Tiktok', description: 'Schedule and analyze videos on your Tiktok accounts.', icon: 'https://img.icons8.com/fluency/48/000000/tiktok.png', buttonText: 'Coming Soon', disabled: true },
    { name: 'YouTube', description: 'Schedule and analyze Shorts on your YouTube channel.', icon: 'https://img.icons8.com/fluency/48/000000/youtube-play.png', buttonText: 'Coming Soon', disabled: true },
  ];
  const [url, setUrl] = useState('');




  useEffect(() => {


    const fetchData = async () => {
      const response = await fetch('https://onetappost.com/api/getLoginUrl', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('ai-tk')}`,
          'Content-Type': 'application/json',
        },
      });
      const json = await response.json();
      setUrl(json.data.url);
      console.log(json.data.url);
    };

    fetchData();
  }, []);


  // insta_accessToken from userAuth

  const handleDisconnect = async () => {
    try {
      const response = await axiosApi.post('/disconnectInsta');
      if (response.data.error === false) {

        setInsta_accessToken(null);

        console.log('Instagram disconnected successfully');
      } else {
        // Handle unexpected response
        console.error('Unexpected response from server:', response.data);
      }
    } catch (error) {
      console.error('Error disconnecting Instagram:', error);

    }
  };



  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-lg font-bold text-gray-800 font-poppins mb-6">
          Connect your social network profiles here. Need help? <span className="text-blue-500 cursor-pointer">Connect with us</span> to complete this step.
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {platforms.map((platform, index) => (
            <div key={index} className="bg-white p-4 rounded-lg shadow hover:shadow-lg transition-shadow duration-200 relative">
              {platform.name === 'Instagram' && insta_accessToken && (
                <div className="absolute top-1 right-1 bg-green-500 text-white text-xs font-semibold px-2 py-1 rounded-full flex items-center">

                  Connected
                </div>
              )}
              <div className="flex items-center mb-4">
                <img src={platform.icon} alt={platform.name} className="w-12 h-12 mr-4" />
                <div>
                  <h2 className="text-lg font-semibold">{platform.name}</h2>
                  <p className="text-gray-600 text-sm">{platform.description}</p>
                </div>
              </div>
              {platform.name === 'Instagram' && insta_accessToken ? (
                <button
                  onClick={handleDisconnect}
                  className="bg-red-500 mt-4 w-full py-2 rounded-lg text-white transition-colors duration-200 hover:bg-red-600"
                >
                  Disconnect
                </button>
              ) : platform.link ? (
                <a href={url}>
                  <button
                    className="bg-gradient-to-r from-[#16B197] to-[#2091DC] mt-4 w-full py-2 rounded-lg text-white transition-colors duration-200 hover:opacity-90"
                  >
                    {platform.buttonText}
                  </button>
                </a>
              ) : (
                <button
                  className={`mt-4 w-full py-2 rounded-lg text-white transition-colors duration-200 ${platform.disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-[#16B197] to-[#2091DC] hover:opacity-90'}`}
                  disabled={platform.disabled}
                >
                  {platform.buttonText}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Profile;
