import UserProfile from "./UserProfile"

const AccountManage = () => {

  return (
    <div className=" pr-32 pl-32 min-h-screen">


      <div className="">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Account Management
        </h2>
        <div className="mb-4">
          <p className="text-gray-800 font-semibold">
            Confused Between Which Plans to Choose?
          </p>
          <p className="text-gray-600">
            Move to a Free plan to continue using Social.ai. You can make 15-AI
            generated content and can only be published with a Social.ai
            watermark.
          </p>
          <button className="mt-2 bg-gray-200 text-gray-700 px-4 py-2 rounded-md">
            Switch To Free Plan
          </button>
        </div>
        <div>
          <p className="text-gray-800 font-semibold">Delete Account</p>
          <p className="text-gray-600">
            This will delete all your generated content and also remove you from
            our mailing lists. The data cannot be recovered later.
          </p>
          <button className="mt-2 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-200">
            Delete
          </button>
        </div>
      </div>

    </div>
  )
}
export default AccountManage