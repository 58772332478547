import React, { useState, useCallback, useMemo } from 'react';
import { FaUserCircle, FaCalendarAlt, FaLightbulb, FaCog, FaBars, FaTimes, FaRegImage, FaPortrait, FaBoxOpen, FaEdit, FaPaintBrush } from 'react-icons/fa';
import { RiDashboardFill, RiAccountCircleLine, RiShoppingBagLine } from 'react-icons/ri';
import { MdContentCopy } from 'react-icons/md';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import logoImg from '../LandingPage/assets/images/logosaas.png';
import { useAuth } from '../api/AuthContext';
import { logout } from '../api/auth';
import UpgradeButton from './UpgradeButton';

const SidebarItem = ({ to, icon: Icon, label, active, onClick }) => (
  <li>
    <Link to={to}>
      <button
        className={`flex items-center w-full py-3 px-4 rounded-lg transition-colors duration-200 ${active ? 'bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white' : 'hover:bg-gray-100 text-[#414962]'
          }`}
        onClick={onClick}
      >
        <Icon className="mr-3 text-lg" />
        <span className="font-poppins text-sm font-medium">{label}</span>
      </button>
    </Link>
  </li>
);

const Sidebar = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(location.pathname);

  const handleItemClick = (path) => {
    setActiveItem(path);
  };

  const getButtonClasses = (path) => {

    return `flex items-center w-full py-2 px-3 rounded-lg ${activeItem === path ? 'bg-[#E8F0FF] font-semibold' : 'hover:bg-gray-100'
      }`;
  };

  return (
    <div className="w-64 h-screen bg-white shadow-md flex flex-col">

      <button className="bg-gradient-to-r from-[#16B197] to-[#2091DC]  text-white py-2 px-4 mx-4 rounded-lg flex items-center justify-center my-4">
        <FaUserCircle className="mr-2" />
        <span>Create New</span>
      </button>
      <div className="px-4">
        <ul className="space-y-2">
          <li>
            <Link to="/dashboard">
              <button
                className={getButtonClasses('/dashboard')}
                onClick={() => handleItemClick('/dashboard')}
              >
                <RiDashboardFill className="mr-3" />
                <span className="text-[#414962] font-poppins text-[14px] font-[500px]">Dashboard</span>
              </button>
            </Link>
          </li>
          <li>
            <Link to="/describe">
              <button
                className={getButtonClasses('/describe')}
                onClick={() => handleItemClick('/describe')}
              >
                <MdContentCopy className="mr-3" />
                <span className="text-[#414962] font-poppins text-[14px] font-[500px]">Create Post</span>
              </button>
            </Link>
          </li>
          <li>
            <Link to="/caption">
              <button
                className={getButtonClasses('/caption')}
                onClick={() => handleItemClick('/caption')}
              >
                <MdContentCopy className="mr-3" />
                <span className="text-[#414962] font-poppins text-[14px] font-[500px]">Generate Captions</span>
              </button>
            </Link>
          </li>
          <li>
            <Link to="/scheduler">
              <button
                className={getButtonClasses('/scheduler')}
                onClick={() => handleItemClick('/scheduler')}
              >
                <FaCalendarAlt className="mr-3" />
                <span className="text-[#414962] font-poppins text-[14px] font-[500px]">Content Calendar</span>
              </button>
            </Link>
          </li>
          <li>
            <Link to="/copywriter">
              <button
                className={getButtonClasses('/copywriter')}
                onClick={() => handleItemClick('/copywriter')}
              >
                <RiShoppingBagLine className="mr-3" />
                <span className="text-[#414962] font-poppins text-[14px] font-[500px]">Copywriter</span>
              </button>
            </Link>

          </li>
          <li>
            <Link to='/idea'>
              <button
                className={getButtonClasses('/idea-lab')}
                onClick={() => handleItemClick('/idea-lab')}
              >
                <FaLightbulb className="mr-3" />
                <span className="text-[#414962] font-poppins text-[14px] font-[500px]">Idea Lab</span>
              </button>
            </Link>
          </li>

          {/* Give a Header for AI Tools */}
          <li>
            <span className="text-[#414962] font-poppins text-[14px] font-[500px]">AI Tools</span>
          </li>
          <li>
            <Link to='/text-to-image'>
              <button
                className={getButtonClasses('/text-to-image')}
                onClick={() => handleItemClick('/text-to-image')}
              >
                <FaRegImage className="mr-3" />
                <span className="text-[#414962] font-poppins text-[14px] font-[500px]">Text to Image</span>
              </button>
            </Link>
          </li>

          <li>
            <Link to='/ai-portrait'>
              <button
                className={getButtonClasses('/ai-portrait')}
                onClick={() => handleItemClick('/ai-portrait')}
              >
                <FaPortrait className="mr-3" />
                <span className="text-[#414962] font-poppins text-[14px] font-[500px]">AI Portrait</span>
              </button>
            </Link>
          </li>
          <li>
            <Link to='/product-mockup'>
              <button
                className={getButtonClasses('/product-mockup')}
                onClick={() => handleItemClick('/product-mockup')}
              >
                <FaBoxOpen className="mr-3" />
                <span className="text-[#414962] font-poppins text-[14px] font-[500px]">Product Mockup</span>
              </button>
            </Link>
          </li>







        </ul>
      </div>
      <div className="mt-auto px-4 mb-6">
        <Link to='subscription'>
          <button
            className='ml-6 hover:scale-105 transition-all px-14 py-2 mb-2 rounded-lg bg-gradient-to-r from-[#6CE7FE] to-[#f081ba] text-black font-poppins font-semibold text-lg'>
            Upgrade
          </button>
        </Link>
        <ul className="space-y-2">
          <li>
            <Link to="/user">
              <button
                className={getButtonClasses('/account')}
                onClick={() => handleItemClick('/account')}
              >
                <RiAccountCircleLine className="mr-3" />
                <span className="text-[#414962] font-poppins text-[14px] font-[500px]">My Account</span>
              </button>
            </Link>
          </li>
          <li>
            <Link to='/help'>
              <button
                className={getButtonClasses('/help')}
                onClick={() => handleItemClick('/help')}
              >
                <FaCog className="mr-3" />
                <span className="text-[#414962] font-poppins text-[14px] font-[500px]">Help?</span>
              </button>
            </Link>
          </li>
        </ul>

      </div>


    </div>
  );
};

const HeaderSidebar = (isMobile) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  

  // Toggles for sidebar and dropdown
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // Logout handler
  const handleLogout = useCallback(async () => {
    try {
      await logout();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Failed to logout:", error);
    }
  }, [navigate]);

  return (
    <div className="flex flex-col h-screen">
      {/* Header */}
      
      <header className="sticky top-0 z-50 bg-white flex items-center justify-between px-3 py-0">
        {/* Logo and Sidebar Toggle */}
        {!isMobile &&
        <div className="flex items-center">
          <Link to="/dashboard">
            <div className="p-2 flex items-center ">
              <img src={logoImg} alt="onetap-logo" className="w-12 h-12" />
              <span className="text-xl font-semibold ml-2 bg-gradient-to-r from-[#16B197] to-[#2091DC] text-transparent bg-clip-text">
                OneTap Post
              </span>
            </div> 
          </Link>

          <button className="md:ml-4" onClick={toggleSidebar}>
            {sidebarOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        }
         {isMobile &&
         
        <div className="flex items-center">
          <button className="md:ml-4" onClick={toggleSidebar}>
            {sidebarOpen ? <FaTimes /> : <FaBars />}
          </button>

          <Link to="/dashboard">
            <div className="p-2 flex items-center md:ml-6 ">
              <img src={logoImg} alt="onetap-logo" className="w-12 h-12" />
              <span className="text-xl font-semibold ml-2 bg-gradient-to-r from-[#16B197] to-[#2091DC] text-transparent bg-clip-text">
                OneTap Post
              </span>
            </div> 
          </Link>

          
        </div>
        }

        {/* User Dropdown */}
        <div className="relative ml-4">
          <button onClick={toggleDropdown} className="flex items-center gap-2">
            <img
              className="w-9 h-9 object-cover rounded-lg"
              src="https://alexpolo.onetapaisolutions.com/images/required/profile.png"
              alt="avatar"
            />
            <h3 className="text-sm">
              Hello <b className="font-semibold">{user ? user.data.name : 'Guest'}</b>
            </h3>
          </button>

          {/* Dropdown Menu */}
          {dropdownOpen && (
            <div className="absolute top-full right-0 mt-2 z-50 bg-gray-800 text-white rounded-xl shadow-md p-4 w-64">
              <div className="text-center mb-5">
                <img
                  className="w-[90px] h-[90px] rounded-full shadow-avatar"
                  src="https://alexpolo.onetapaisolutions.com/images/required/profile.png"
                  alt="avatar"
                />
                <h3 className="font-medium text-sm">{user ? user.data.name : 'Guest'}</h3>
                <p className="text-xs">{user ? user.data.email : 'guest@example.com'}</p>
                <p className="text-xs">{user ? user.data.phone : '+1234567890'}</p>
                <h3 className="font-medium text-sm capitalize mb-0.5">$0.00</h3>
              </div>

              <nav>
                <Link to="/admin/profile/edit-profile" className="block py-3 border-b text-sm">
                  Edit Profile
                </Link>
                <Link to="/admin/profile/change-password" className="block py-3 border-b text-sm">
                  Change Password
                </Link>
                <button className="block py-3 text-sm text-red-500" onClick={handleLogout}>
                  Logout
                </button>
              </nav>
            </div>
          )}
        </div>
      </header>


      {/* Sidebar and Main Content */}
      <div className="flex flex-1 overflow-hidden">
        {sidebarOpen &&  (
          <div className={`transform transition-transform duration-300 ease-in-out md:translate-x-0 ${sidebarOpen ? 'sm:z-30 translate-x-0' : '-translate-x-full'}`}>
            <Sidebar />
          </div>
        )}
         {isMobile &&
        <main className={`flex-1 overflow-y-auto p-6 bg-gray-100 ${sidebarOpen ? '' : 'w-full '}   ` }>
          <Outlet />
        </main>
}
        {/* {isMobile && 
         <main className={` ${sidebarOpen ? '' : ''}` }>
         <Outlet />
       </main>} */}
      </div>
    </div>
  );
};

export default HeaderSidebar;
