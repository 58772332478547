import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const NotFound404 = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-500 to-purple-600 flex items-center justify-center px-4">
      <div className="max-w-lg w-full text-center">
        <h1 className="text-9xl font-bold text-white mb-8 animate-bounce">404</h1>
        <p className="text-2xl text-white mb-8">Oops! Looks like you're lost in space.</p>
        <div className="relative mb-8">
          <div 
            className="absolute inset-0 bg-white opacity-20 rounded-full blur-md"
            style={{
              transform: `translate(${(position.x - window.innerWidth / 2) / 50}px, ${(position.y - window.innerHeight / 2) / 50}px)`,
            }}
          ></div>
          <img 
            src="/astronaut.svg" 
            alt="Lost Astronaut" 
            className="w-64 h-64 mx-auto relative z-10"
            style={{
              transform: `translate(${(position.x - window.innerWidth / 2) / 30}px, ${(position.y - window.innerHeight / 2) / 30}px)`,
            }}
          />
        </div>
        <Link 
          to="/" 
          className="bg-white text-purple-600 px-6 py-3 rounded-full font-semibold hover:bg-opacity-90 transition duration-300"
        >
          Return Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound404;