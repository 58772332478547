import React, { useState } from 'react';
import TemplateCard from './TemplateCard';
import { FaSearch } from 'react-icons/fa';

import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
  FaGoogle,
  FaAmazon,
  FaPinterest,
  FaSnapchat,
  FaTiktok,
} from 'react-icons/fa';

export const templates = [
  { id: 1, category: 'Social Media', title: 'Facebook Post Ideas', description: 'Engage your audience with compelling Facebook posts.', icon: <FaFacebook />, color: '#3b5998' },
  { id: 2, category: 'Social Media', title: 'Instagram Caption Generator', description: 'Generate captivating Instagram captions to boost engagement.', icon: <FaInstagram />, color: '#C13584' },
  { id: 3, category: 'Social Media', title: 'Twitter Post Ideas', description: 'Create viral tweets that resonate with your followers.', icon: <FaTwitter />, color: '#1DA1F2' },
  { id: 4, category: 'Ads', title: 'LinkedIn Ad Copy', description: 'Reach professionals with effective LinkedIn ad copy.', icon: <FaLinkedin />, color: '#0077B5' },
  { id: 5, category: 'Video', title: 'YouTube Video Ideas', description: 'Find inspiration for your next YouTube video.', icon: <FaYoutube />, color: '#FF0000' },
  { id: 6, category: 'SEO', title: 'SEO Meta Description', description: 'Optimize your posts with SEO-friendly meta descriptions.', icon: <FaGoogle />, color: '#4285F4' },
  { id: 7, category: 'E-commerce', title: 'Amazon Product Descriptions', description: 'Write compelling product descriptions for Amazon listings.', icon: <FaAmazon />, color: '#FF9900' },
  { id: 8, category: 'Ads', title: 'Instagram Ad Copy', description: 'Create ads that stop users from scrolling on Instagram.', icon: <FaInstagram />, color: '#C13584' },
  { id: 9, category: 'Social Media', title: 'Pinterest Pin Descriptions', description: 'Craft descriptions that make your pins stand out.', icon: <FaPinterest />, color: '#BD081C' },
  { id: 10, category: 'Social Media', title: 'Snapchat Story Ideas', description: 'Engage your audience with creative Snapchat stories.', icon: <FaSnapchat />, color: '#FFFC00' },
  { id: 11, category: 'Social Media', title: 'TikTok Video Ideas', description: 'Create trending content for TikTok that goes viral.', icon: <FaTiktok />, color: '#010101' },
  { id: 12, category: 'Ads', title: 'Google Ad Copy', description: 'Craft effective ad copy to dominate Google search results.', icon: <FaGoogle />, color: '#4285F4' },
  { id: 13, category: 'Social Media', title: 'LinkedIn Post Ideas', description: 'Boost your professional presence with engaging LinkedIn posts.', icon: <FaLinkedin />, color: '#0077B5' },
  { id: 14, category: 'Ads', title: 'Twitter Ad Copy', description: 'Create ads that convert on Twitter.', icon: <FaTwitter />, color: '#1DA1F2' },
  { id: 15, category: 'Video', title: 'YouTube Thumbnails', description: 'Design thumbnails that attract clicks on YouTube.', icon: <FaYoutube />, color: '#FF0000' },
  { id: 16, category: 'Social Media', title: 'Instagram Story Ideas', description: 'Create engaging Instagram stories to connect with your audience.', icon: <FaInstagram />, color: '#C13584' },
  { id: 17, category: 'SEO', title: 'Blog Post Titles', description: 'Generate SEO-friendly blog post titles to improve search rankings.', icon: <FaGoogle />, color: '#4285F4' },
  { id: 18, category: 'Social Media', title: 'Facebook Ad Copy', description: 'Write ad copy that increases clicks and conversions on Facebook.', icon: <FaFacebook />, color: '#3b5998' },
  { id: 19, category: 'Ads', title: 'Pinterest Ad Copy', description: 'Create visually appealing ad copy for Pinterest.', icon: <FaPinterest />, color: '#BD081C' },
  { id: 20, category: 'Video', title: 'YouTube Description Generator', description: 'Generate descriptions that keep viewers watching.', icon: <FaYoutube />, color: '#FF0000' },
  { id: 21, category: 'E-commerce', title: 'Product Review Request', description: 'Ask customers for reviews in a way that increases responses.', icon: <FaAmazon />, color: '#FF9900' },
];

const categories = ['All', 'Social Media', 'Ads', 'Video', 'SEO', 'E-commerce'];

const Copywriter = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [filteredTemplates, setFilteredTemplates] = useState(templates);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    filterTemplates(value, selectedCategory);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    filterTemplates(searchTerm, category);
  };

  const filterTemplates = (searchTerm, category) => {
    let filtered = templates.filter((template) =>
      template.title.toLowerCase().includes(searchTerm) ||
      template.description.toLowerCase().includes(searchTerm)
    );

    if (category !== 'All') {
      filtered = filtered.filter((template) => template.category === category);
    }

    setFilteredTemplates(filtered);
  };

  return (
    <div className="min-h-screen bg-gray-50 p-4 sm:p-8"> {/* Reduced padding for mobile */}
      <div className="flex flex-col sm:flex-row items-center justify-between mb-6 sm:mb-10">
        <div className="mb-4 sm:mb-0">
          <h1 className="text-xl sm:text-2xl font-bold text-gray-900">All Templates</h1>
          <p className="text-gray-700 mt-1 text-sm sm:text-base">
            Efficiently streamline your writing process with a comprehensive collection of templates.
          </p>
        </div>
        <div className="relative w-full sm:max-w-xs">
          <input
            type="text"
            placeholder="Search templates..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm focus:ring-indigo-500 focus:border-indigo-500 shadow-sm"
          />
          <FaSearch className="w-4 h-4 absolute left-3 top-3.5 text-gray-400" />
        </div>
      </div>

      <div className="flex flex-wrap gap-2 sm:gap-4 mb-6 sm:mb-8 bg-white rounded-lg p-2 sm:p-4 shadow-sm">
        {categories.map((category) => (
          <button
            key={category}
            className={`px-3 py-1 sm:px-4 sm:py-2 rounded-md transition-all duration-200 text-xs sm:text-sm font-semibold ${selectedCategory === category
              ? 'bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white shadow-md'
              : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
              }`}
            onClick={() => handleCategoryClick(category)}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
        {filteredTemplates.map((template, index) => (
          <TemplateCard
            id={template.id}
            key={index}
            title={template.title}
            description={template.description}
            icon={template.icon}
            color={template.color}
            badge="Standard"
          />
        ))}
      </div>

      {filteredTemplates.length === 0 && (
        <p className="text-center text-gray-500 mt-6">No templates found.</p>
      )}
    </div>
  );
};

export default Copywriter;
