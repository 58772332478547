import React, { useState, useEffect } from 'react';
import { createBrowserRouter, Outlet, useLocation, Navigate } from 'react-router-dom';
import './App.css';
import DescribePost from './ImageComponents/DescribePost';
import ResponseDisplay from './ImageComponents/ResponseDisplay';
import SocialMedia from './ImageComponents/SocialMedia';
import Schdueler from './ImageComponents/Schdueler';
import "react-big-calendar/lib/css/react-big-calendar.css";
import Dashboard from './ImageComponents/Dashboard';
import Signup from './ImageComponents/Signup';
import SignIn from './ImageComponents/Signin';
import ForgotPassword from './ImageComponents/ForgotPassword';
import MyAccount from './ImageComponents/MyAccount';
import Copywriter from './ImageComponents/Copywriter';
import Session from './ImageComponents/Session';
import Profile from './ImageComponents/Profiles';

import PostContainer from './PostsUI/PostContainer';
import TwitterBigPost from './PostsUI/TwitterBigPost';
import InstagramBigPost from './PostsUI/InstagramBigPost';
import FacebookBigPost from './PostsUI/FacebookBigPost';
import PinterestBigPost from './PostsUI/PinterestBigPost';
import LinkedInBigPost from './PostsUI/LinkedInBigPost';
import { AuthProvider } from './api/AuthContext';
import HelpPage from './ImageComponents/Help';
import IdeaLab from './ImageComponents/IdeaLab';
import MyCalendar from './Components/BigCalendar';
import Subscription from './ImageComponents/Subscription';
import AccountManage from './ImageComponents/AccountManage';
import User from './ImageComponents/User';
import TemplateEditor from './ImageComponents/TemplateEditor';
import Home from './LandingPage/Home';
import HeaderSidebar from './ImageComponents/Sidebar';
import PostEditor from './ImageComponents/NewSocial';
import ProtectedRoute from './api/ProtectedRoutes';
import PrivacyPolicy from './Components/PrivacyPolicy';
import NotFound404 from './Components/NotFound404';
import TermsOfService from './Components/TermsOfService';
import UpgradeButton from './ImageComponents/UpgradeButton';
// Main App Component
import { FaBars, FaTimes } from 'react-icons/fa'; // Icons for open/close

import TextToImage from './Components/TextToImage';
import AIPortrait from './ImageComponents/AIPortrait';
import { analytics, logEvent } from './firebase';
import ErrorBoundary from './Components/ErrorBoundary';
import CreateCaption from './ImageComponents/CreateCaption';
import ImageCaptioner from './ImageComponents/CreateCaption';


// Main App Component
export const App = () => {
  const location = useLocation();
  const showSidebar = location.pathname !== '/' &&
    location.pathname !== '/signup' &&
    location.pathname !== '/signin' &&
    location.pathname !== '/forgotpassword' &&
    location.pathname !== '/edit-template' &&
    location.pathname !== '/privacy-policy' &&
    location.pathname !== '/terms-of-service' &&
    location.pathname !== '/faqs' &&
    location.pathname !== '/features' &&
    location.pathname !== '/pricing' &&
    location.pathname !== '*';

  const [isMobile, setIsMobile] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false); // New state to control sidebar open/close

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Change this value based on your breakpoint
    };
    { console.log(isMobile) }
    window.addEventListener('resize', handleResize);
    handleResize(); // Check the screen size on component mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev); // Toggle sidebar visibility
  };

  useEffect(() => {
    // Log page views
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_title: document.title,
    });
  }, [location]);

  return (
    <ErrorBoundary>
      <div className="flex flex-col h-screen">
        {/* Sidebar Toggle Button for Mobile */}
        {/* {showSidebar && isMobile && (
        <button
          className="fixed top-4 left-4 z-50 p-2 bg-gray-800 text-white rounded-md"
          onClick={toggleSidebar}
        >
          {sidebarOpen ? <FaTimes /> : <FaBars />}
        </button>
      )}
       */}
        {/* Sidebar */}
        {showSidebar && (sidebarOpen || !isMobile) && (

          <HeaderSidebar /> // Pass props for handling mobile sidebar
        )}
        {showSidebar && isMobile && (

          <HeaderSidebar isMobile={isMobile} /> // Pass props for handling mobile sidebar
        )}

        {/* Main Content */}
        <div className="flex flex-1 overflow-y-auto">
          <div className={`flex-1 bg-gray-50 ${showSidebar && !isMobile ? 'w-full' : 'w-full'}`}>
            <Outlet />
          </div>
        </div>

      </div>
    </ErrorBoundary>

  );
};



// Router Configuration
export const AppRouter = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <App />
      </AuthProvider>
    ),
    children: [
      // Unprotected routes
      {
        path: '/',
        element: <Home />
      },
      {
        path: 'signup',
        element: <Signup />
      },
      {
        path: 'signin',
        element: <SignIn />
      },
      {
        path: 'forgotpassword',
        element: <ForgotPassword />
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />
      },

      {
        path: 'terms-of-service',
        element: <TermsOfService />
      },
      // Protected routes

      // text-to-image
      {
        path: 'text-to-image',
        element: <ProtectedRoute><TextToImage /></ProtectedRoute>
      },
      {
        path: 'caption',
        element: <ProtectedRoute><ImageCaptioner /></ProtectedRoute>
      },
      {
        path: 'ai-portrait',
        element: <ProtectedRoute><AIPortrait /></ProtectedRoute>
      },
      {
        path: 'dashboard',
        element: <ProtectedRoute><Dashboard /></ProtectedRoute>
      },
      {
        path: 'describe',
        element: <ProtectedRoute><DescribePost /></ProtectedRoute>
      },
      {
        path: 'response',
        element: <ProtectedRoute><ResponseDisplay /></ProtectedRoute>
      },
      {
        path: 'social',
        element: <ProtectedRoute><SocialMedia /></ProtectedRoute>
      },
      {
        path: 'scheduler',
        element: <ProtectedRoute><Schdueler /></ProtectedRoute>
      },
      {
        path: 'account',
        element: <ProtectedRoute><MyAccount /></ProtectedRoute>
      },
      {
        path: 'copywriter',
        element: <ProtectedRoute><Copywriter /></ProtectedRoute>
      },
      {
        path: 'session',
        element: <ProtectedRoute><Session /></ProtectedRoute>
      },
      {
        path: 'profile',
        element: <ProtectedRoute><Profile /></ProtectedRoute>
      },

      {
        path: 'postcontainer',
        element: <ProtectedRoute><PostContainer /></ProtectedRoute>
      },
      {
        path: 'twitterbig',
        element: <ProtectedRoute><TwitterBigPost /></ProtectedRoute>
      },
      {
        path: 'instabig',
        element: <ProtectedRoute><InstagramBigPost /></ProtectedRoute>
      },
      {
        path: 'fbbig',
        element: <ProtectedRoute><FacebookBigPost /></ProtectedRoute>
      },
      {
        path: 'pinbig',
        element: <ProtectedRoute><PinterestBigPost /></ProtectedRoute>
      },
      {
        path: 'linkbig',
        element: <ProtectedRoute><LinkedInBigPost /></ProtectedRoute>
      },
      {
        path: 'help',
        element: <ProtectedRoute><HelpPage /></ProtectedRoute>
      },
      {
        path: 'idea',
        element: <ProtectedRoute><IdeaLab /></ProtectedRoute>
      },
      {
        path: 'bigcalendar',
        element: <ProtectedRoute><MyCalendar /></ProtectedRoute>
      },
      {
        path: 'subscription',
        element: <ProtectedRoute><Subscription /></ProtectedRoute>
      },
      {
        path: 'manageaccount',
        element: <ProtectedRoute><AccountManage /></ProtectedRoute>
      },
      {
        path: 'user',
        element: <ProtectedRoute><User /></ProtectedRoute>,
        children: [
          { path: ':section', element: <User /> },
          { index: true, element: <Navigate to="/user/personalInfo" replace /> },
        ]
      },
      {
        path: 'copywriter/:id',
        element: <ProtectedRoute><TemplateEditor /></ProtectedRoute>
      },
      {
        path: 'posteditor',
        element: <ProtectedRoute><PostEditor /></ProtectedRoute>
      },
      {
        path: 'upgrade',
        element: <ProtectedRoute><UpgradeButton /></ProtectedRoute>
      },

      // Add new routes for landing page sections
      {
        path: 'faqs',
        element: <Home section="faqs" />
      },
      {
        path: 'features',
        element: <Home section="features" />
      },
      {
        path: 'pricing',
        element: <Home section="pricing" />
      },

      // Add this at the end of the children array
      {
        path: '*',
        element: <NotFound404 />
      }
    ]
  }
]);





