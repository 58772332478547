import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyD64Rq_DGD_hgPH3WJNaB4-aBPtYt17LIM",
    authDomain: "midjourney-ai-1d93b.firebaseapp.com",
    projectId: "midjourney-ai-1d93b",
    storageBucket: "midjourney-ai-1d93b.appspot.com",
    messagingSenderId: "23287676386",
    appId: "1:23287676386:web:d01cdad754659f872ccdbb",
    measurementId: "G-71SPQ3KWJ8"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics, logEvent };