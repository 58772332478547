import React, { useState } from 'react';
import { FaCloudUploadAlt, FaRedoAlt } from 'react-icons/fa';

function ImageCaptioner() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [caption, setCaption] = useState('');
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    setCaption('');
    if (e.target.files && e.target.files[0]) {
      const image = e.target.files[0];
      setSelectedImage(image);
    }
  };

  const generateCaption = async (image) => {
    setLoading(true);

    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = async () => {
      const base64data = reader.result;
      const base64Image = base64data.replace(/^data:image\/[a-z]+;base64,/, '');

      try {
        const response = await fetch(
          'https://api-inference.huggingface.co/models/nlpconnect/vit-gpt2-image-captioning',
          {
            method: 'POST',
            headers: {
              Authorization: 'Bearer YOUR_HUGGINGFACE_API_KEY',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              inputs: base64Image,
            }),
          }
        );
        const result = await response.json();
        if (response.ok) {
          setCaption(result[0].generated_text);
        } else {
          setCaption('Error generating caption');
        }
      } catch (error) {
        console.error(error);
        setCaption('Error generating caption');
      } finally {
        setLoading(false);
      }
    };
  };

  const handleSubmit = () => {
    if (!selectedImage) return;
    generateCaption(selectedImage);
  };

  const handleRegenerate = () => {
    if (!selectedImage) return;
    generateCaption(selectedImage);
  };

  // Drag and Drop Handlers
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    setCaption('');
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const image = e.dataTransfer.files[0];
      setSelectedImage(image);
    }
  };

  return (
    <div className="min-h-screen bg-[#F3F4F6] flex items-center justify-center font-poppins p-4">
      <div className="w-full max-w-5xl bg-[#F3F4F6] rounded-lg shadow-lg p-8">
        <h1 className="text-3xl font-semibold  text-center mb-6">
          Image Caption Generator
        </h1>
        <div className="flex flex-col md:flex-row">
          {/* Left Side - Image Upload and Display */}
          <div
            className="md:w-1/2 mb-6 md:mb-0 md:mr-4"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <label
              className="flex flex-col items-center justify-center border-4 border-dashed border-gray-600 rounded-lg h-96 cursor-pointer hover:border-blue-500 transition"
            >
              {selectedImage ? (
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Selected"
                  className="object-contain h-full w-full rounded-md"
                />
              ) : (
                <div className="flex flex-col items-center">
                  <FaCloudUploadAlt className="text-gray-500 text-6xl mb-4" />
                  <p className="text-gray-500 text-center">
                    Drag and drop an image here, or click to select one
                  </p>
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="hidden"
              />
            </label>
            {selectedImage && (
              <button
                onClick={handleSubmit}
                className="mt-4 w-full bg-gradient-to-r from-[#2093D6] to-[#16ACA0] text-white px-6 py-3 rounded-md hover:bg-blue-700 transition disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-3 text-white"
                      viewBox="0 0 24 24"
                    >
                      {/* Spinner SVG path */}
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                      ></path>
                    </svg>
                    Generating...
                  </div>
                ) : (
                  'Generate Caption'
                )}
              </button>
            )}
          </div>

          {/* Right Side - Caption Display and Regenerate Option */}
          <div className="md:w-1/2 md:ml-4 flex flex-col">
            {caption ? (
              <div className="bg-white p-6 rounded-md h-full flex flex-col">
                <h2 className="text-2xl font-semibold  mb-4">
                  Caption
                </h2>
                <p className="text-gray-800 flex-grow">{caption}</p>
                <button
                  onClick={handleRegenerate}
                  className="mt-4 bg-gradient-to-r from-[#16ACA0] to-[#2093D6] text-white px-4 py-2 rounded-md hover:bg-purple-700 transition self-end flex items-center"
                  disabled={loading}
                >
                  <FaRedoAlt className="mr-2" />
                  {loading ? 'Regenerating...' : 'Regenerate Caption'}
                </button>
              </div>
            ) : (
              <div className="bg-white p-6 rounded-md h-96 flex items-center justify-center">
                <p className="text-gray-500 text-center">
                  Your caption will appear here after generating.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageCaptioner;
