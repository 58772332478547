import React from 'react';
import Subscription from './Subscription';
import AccountManage from './AccountManage';
import UserProfile from './UserProfile';

const MyAccount = () => {
  return (
    <div className="bg-gray-100 p-6">



      <div>
        <UserProfile />
      </div>



    </div>
  );
};

export default MyAccount;
