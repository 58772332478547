import Image from 'react-bootstrap/Image';
import { useScroll, useTransform, motion } from "framer-motion";
import { useRef } from "react";

import helixImage from "../LandingPage/assets/images/helix2.png";
import emojiStarImage from "../LandingPage/assets/images/emojistar.png";

export const CallToAction = () => {
    const containerRef = useRef(null);

    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ['start start', 'end end'],
    });

    const translateY = useTransform(scrollYProgress, [0, 1], [-80, 80]);

    return (
        <div className="bg-black text-white py-18 sm:p-24 text-center overflow-hidden" ref={containerRef}>
            <div className="container max-w-xl relative">
                <motion.div style={{ translateY }}>
                    <Image src={helixImage} alt="helix" className="absolute top-6 left-[calc(100%+36px)]" />
                </motion.div>
                <motion.div style={{ translateY }}>
                    <Image src={emojiStarImage} alt="emoji star" className="absolute -top-24 right-[calc(100%+24px)]" />
                </motion.div>
                <h2 className="font-bold text-5xl sm:text-6xl tracking-tighter">Get Started Instantly</h2>
                <p className="text-xl text-white/70 mt-5">
                    Unlock the power of AI-driven content creation and automate your social media strategy effortlessly.
                </p>
                <form className="mt-10 flex flex-col sm:flex-row gap-2.5 max-w-md mx-auto">
                    <input type="email" placeholder="your@email.com" className="bg-white/20 rounded-lg py-3 px-5 outline-none font-medium placeholder:text-gray-400 flex-1" />
                    <button className="bg-white text-black h-12 rounded-lg px-5">Get Access</button>
                </form>
            </div>

        </div>
    );
};
