const Subscription = () => {
  return (
    <div className="min-h-full min-w-screen bg-gray-100 p-4">
      <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">Choose Your Plan</h2>
      <div className="flex justify-center mb-4">
        <button className="bg-gray-300 text-gray-700 px-4 py-2 rounded-l-md border border-gray-400 hover:bg-gray-400">
          Pay Monthly
        </button>
        <button className="bg-gradient-to-r from-[#286CD6] to-[#C13368] text-white px-4 py-2 rounded-r-md border border-gray-400 hover:from-[#1a5abf] hover:to-[#a02c5b]">
          Pay Annually <span className="ml-2 text-xs">50% Off</span>
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {[
          {
            plan: 'Free',
            price: '$0',
            description: 'Forever Free',
            features: [
              '1 Brand',
              '15 Generated Posts/month',
              '10 Competitor Runs/month',
              'No Voice-over',
              'No Premium Assets',
              'No API Access',
              'Publish to 5 channels',
              'Social.ai Watermark',
            ],
          },
          {
            plan: 'Lite',
            price: '$32',
            description: '$192 Billed Monthly',
            features: [
              '1 Brand',
              '60 Generated Posts/month',
              '60 Competitor Runs/month',
              '50 Voice-over minutes',
              '5M+ Premium Assets',
              'No API Access',
              'Publish to 5 channels',
              'Unlimited Team Members',
              'Idea Labs - 25,000 Words/month',
            ],
          },
          {
            plan: 'Premium',
            price: '$59',
            description: '$350 Billed Monthly',
            popular: true,
            features: [
              'Up to 4 brands',
              '130 Generated Posts/month',
              '130 Competitor Runs/month',
              '110 Voice-over minutes',
              '5M+ Premium Assets',
              'API Access (as add-on)',
              'Publish to 10 channels',
              'Unlimited Team Members',
              'Idea Labs - 50,000 Words/month',
            ],
          },
          {
            plan: 'Agency',
            price: '$249',
            description: '$1500 Billed Monthly',
            features: [
              'Unlimited Brands',
              '600 Generated Posts/month',
              '600 Competitor Runs/month',
              '600 Voice-over minutes',
              '5M+ Premium Assets',
              'API Access (as add-on)',
              'Publish to 50 channels',
              'Unlimited Team Members',
              'Idea Labs - 250,000 Words/month',
            ],
          },
        ].map((plan, index) => (
          <div
            key={index}
            className={`relative bg-white p-6 rounded-lg shadow-lg ${plan.popular ? 'border-2 border-blue-500' : ''}`}
          >
            {plan.popular && (
              <div className="absolute -top-4 -right-4 bg-blue-500 text-white p-1 rounded-full text-xs font-bold">
                Most Popular
              </div>
            )}
            <p className="text-xl font-semibold text-gray-800 mb-2">{plan.plan}</p>
            <p className="text-3xl font-bold text-[#1A6AFF]">{plan.price}<span className="text-gray-500 text-sm font-medium">/ month</span></p>
            <p className="text-gray-600 font-medium mb-4">{plan.description}</p>

            <ul className="list-none space-y-2 mb-6">
              {plan.features.map((feature, i) => (
                <li key={i} className="text-gray-700 text-sm">
                  - {feature}
                </li>
              ))}
            </ul>
            <button className="bg-blue-500 text-white w-full py-2 rounded-md hover:bg-blue-600 transition duration-200">
              Choose
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Subscription;
