import React from 'react';
import { useLocation } from 'react-router-dom';

function LinkedInPost() {
    const location = useLocation();
    const { image, caption } = location.state || {};

    // Function to truncate the caption to 10 words
    const truncateCaption = (text = '', maxWords) => {
        const words = text.split(' ');
        if (words.length > maxWords) {
            return words.slice(0, maxWords).join(' ') + '...';
        }
        return text;
    };
  return (
    <div className="w-52 h-52 bg-white border rounded-lg shadow-md p-2 hover:scale-105 transition-all cursor-pointer">
      <div className="flex items-center mb-1">
        <img
          className="w-6 h-6 rounded-full"
          src="https://via.placeholder.com/150"
          alt="Profile"
        />
        <div className="ml-1 text-xs text-gray-800">
          <span className="font-bold">Pushkar Kumar</span>
          <div>Sr SDE @ByteDance</div>
        </div>
      </div>
      <p className="text-xs text-gray-800 mb-1">
      {truncateCaption(caption, 10)}
      </p>
      <img
        className="w-full h-20 rounded-lg mb-1"
        src={image}
        alt="LinkedIn Post"
      />
      <div className="text-xs text-gray-500">Vimal Tripathi and 4,344 others</div>
    </div>
  );
}

export default LinkedInPost;
