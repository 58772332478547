import React from 'react';
import { useLocation } from 'react-router-dom';

function PinterestBigPost({image, caption}) {
 
  return (
    <div className="max-w-md mx-auto bg-white border rounded-lg shadow-md p-4 mt-2">
      <img
        className="w-full h-64 object-cover rounded-lg"
        src={image}
        alt="Sister Hug"
      />
      <div className="flex justify-between items-center mt-4">
        <div>
          <span className="font-bold text-gray-800">{caption}</span>
          <div className="text-gray-500 text-sm">
            #fashion #recipe #christmas #dinner #chicken #halloween #aesthetic ...
          </div>
        </div>
        <button className="text-red-500 hover:text-red-700">
          ❤️ Save
        </button>
      </div>
      <div className="mt-2 text-sm text-gray-500">Reese • 159 followers</div>
    </div>
  );
}

export default PinterestBigPost;
