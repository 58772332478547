import { ReactComponent as InstaIcon } from "../LandingPage/assets/icons/insta.svg";
import { ReactComponent as XSocial } from "../LandingPage/assets/icons/x-social.svg";
import { ReactComponent as TiktokIcons } from "../LandingPage/assets/icons/tiktok.svg";
import { ReactComponent as YoutubeIcon } from "../LandingPage/assets/icons/youtube.svg";

export const Footer = () => {
    return (
        <footer className="py-5 bg-black text-white/60">
            <div className="container border-t border-white/10 py-5">
                <div className="flex flex-col gap-2">
                    <div className="text-center">© 2024 OneTap AI Solutions, Inc. All rights reserved</div>
                    <ul className="flex justify-center gap-2.5">
                        <li><InstaIcon /></li>
                        <li><XSocial /></li>
                        <li><TiktokIcons /></li>
                        <li><YoutubeIcon /></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};
