import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const UserProfile = () => {
  const location = useLocation();

  return (
    <div className="max-w-2xl mx-auto p-8">
      <div className="border-b-2 space-x-16 border-gray-200 mb-6">
        <Link to='/user'>
          <button
            className={`font-semibold pb-2 ${
              location.pathname === '/user'
                ? 'text-blue-500 border-b-2 border-blue-500'
                : 'text-gray-500'
            }`}
          >
            My Profile
          </button>
        </Link>
        <Link to='/subscription'>
          <button
            className={`font-semibold pb-2 ${
              location.pathname === '/subscription'
                ? 'text-blue-500 border-b-2 border-blue-500'
                : 'text-gray-500'
            }`}
          >
            Subscription & Billing
          </button>
        </Link>
        <Link to='/manageaccount'>
          <button
            className={`font-semibold pb-2 ${
              location.pathname === '/manageaccount'
                ? 'text-blue-500 border-b-2 border-blue-500'
                : 'text-gray-500'
            }`}
          >
            Account
          </button>
        </Link>
      </div>
    </div>
  );
};

export default UserProfile;
