import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Banner } from './Banner';
import { LogoTicker } from './LogoTicker';
import { Navbar } from './NavBar';

import { Features } from './Features';
import { ProductShowcase } from './ProductShowcase';
import { FAQs } from './FAQs';
import { CallToAction } from './CallToAction';
import { Hero } from './Hero';
import { Footer } from './Footer';
import PricingComponent from './PricingSection';

const Home = ({ section }) => {
    const location = useLocation();

    useEffect(() => {
        if (section) {
            const element = document.getElementById(section);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [section, location]);

    return (
        <div className=''>
            <Banner />
            <Navbar />
            <Hero />
            <LogoTicker />
            <div id="features">
                <Features />
            </div>
            <ProductShowcase />
            <div id="pricing">
                <PricingComponent />
            </div>
            <div id="faqs">
                <FAQs />
            </div>
            <CallToAction />
            <Footer />
        </div>
    );
};

export default Home;
