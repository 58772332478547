import React, { useEffect, useState, useCallback } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useNavigate } from 'react-router-dom';
import axiosApi from '../api/apiAxios';
import { FaPlus } from 'react-icons/fa';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CACHE_KEY = 'calendarEvents';
const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes in milliseconds

const MyCalendar = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const fetchEvents = useCallback(async () => {
    try {
      const response = await axiosApi.get('/getPostResponseById');
      const fetchedEvents = response.data.data.map((res) => ({
        id: res.id,
        title: res.caption,
        start: new Date(res.date),
        end: new Date(res.date),
        image: res.image,
        caption: res.caption,
      }));
      setEvents(fetchedEvents);
      localStorage.setItem(CACHE_KEY, JSON.stringify({
        timestamp: Date.now(),
        data: fetchedEvents,
      }));
    } catch (error) {
      console.error("Error fetching events: ", error);
    }
  }, []);

  useEffect(() => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    const now = Date.now();

    if (cachedData) {
      const { timestamp, data } = JSON.parse(cachedData);
      if (now - timestamp < CACHE_EXPIRY) {
        setEvents(data.map(event => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end)
        })));
        return;
      }
    }
    fetchEvents();
  }, [fetchEvents]);

  const handleEventClick = useCallback((event) => {
    navigate('/posteditor', {
      state: {
        date: event.start,
        image: event.image,
        caption: event.caption,
        id: event.id,
      }
    });
  }, [navigate]);

  return (
    <div className="container  px-4 bg-[#EFF3F6] w-full  min-h-screen py-6">
      {/* Calendar Header */}
      <div className="bg-white shadow-md p-4 sm:p-6 rounded-lg flex flex-col sm:flex-row items-start sm:items-center justify-between mb-6">
        <div className="mb-4 sm:mb-0">
          <h1 className="text-xl sm:text-2xl font-bold text-gray-900">Scheduled Posts Calendar</h1>
          <p className="text-gray-600 text-sm sm:text-base mt-1">View and manage all your scheduled posts in one place</p>
        </div>
        <button
          className="bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white py-2 px-3 sm:px-4 rounded-lg flex items-center justify-center hover:opacity-90 transition-opacity"
          onClick={() => navigate('/new-social')}
        >
          <FaPlus className="mr-2" />
          Create New Post
        </button>
      </div>

      {/* Calendar Component */}
      <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 'auto', minHeight: 500 }}
          onSelectEvent={handleEventClick}
          eventPropGetter={() => ({
            style: {
              backgroundColor: '#f0f4f8',
              border: '1px solid #e2e8f0',
              borderRadius: '8px',
              color: '#4a5568',
            },
          })}
          components={{
            event: EventComponent,
          }}
          className="custom-calendar"
        />
      </div>
    </div>
  );
};

const EventComponent = ({ event }) => (
  <div className="flex items-center p-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer">
    {event.image && (
      <img
        src={event.image}
        alt={event.title}
        className="h-8 sm:h-10 w-8 sm:w-10 object-cover mr-2 sm:mr-3 rounded-full border-2 border-[#16B197]"
      />
    )}
    <div className="flex flex-col">
      <span className="text-xs sm:text-sm font-medium text-gray-900 truncate">{event.title}</span>
      <span className="text-xs text-gray-500">{format(event.start, 'h:mm a')}</span>
    </div>
  </div>
);

export default MyCalendar;
