import { analytics, logEvent } from '../firebase';

const useAnalytics = () => {
    const logCustomEvent = (eventName, eventParams = {}) => {
        logEvent(analytics, eventName, eventParams);
    };

    return { logCustomEvent };
};

export default useAnalytics;