import { useState } from "react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";

import { ReactComponent as PlusIcon } from "../LandingPage/assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "../LandingPage/assets/icons/minus.svg";

const items = [
    {
        question: "What payment methods do you accept?",
        answer:
            "We accept all major credit cards, PayPal, and various other payment methods depending on your location. Please contact our support team for more information on accepted payment methods in your region.",
    },
    {
        question: "How does the pricing work for teams?",
        answer:
            "Our pricing is per user, per month. You only pay for the number of team members you have on your account. Discounts are available for larger teams and annual subscriptions.",
    },
    {
        question: "Can I change my plan later?",
        answer:
            "Yes, you can upgrade or downgrade your plan at any time. Changes to your plan will be prorated and reflected in your next billing cycle.",
    },
    {
        question: "Is my data secure?",
        answer:
            "Security is our top priority. We use state-of-the-art encryption and comply with the best industry practices to ensure that your data is stored securely and accessed only by authorized users.",
    },
    {
        question: "How does AI help with content creation?",
        answer:
            "Our AI analyzes your brand's voice and audience preferences to generate tailored content that resonates with your followers, saving you time and boosting engagement.",
    },
    {
        question: "Can I schedule posts in advance?",
        answer:
            "Absolutely! Our platform allows you to plan and schedule your content across all major social media platforms, ensuring your posts go live at the optimal time.",
    },
    {
        question: "What social media platforms do you support?",
        answer:
            "We support all major social media platforms including Facebook, Instagram, Twitter, LinkedIn, and more. Our platform is continually updated to support the latest platforms and features.",
    },
    {
        question: "Do you offer analytics and reporting?",
        answer:
            "Yes, our platform provides comprehensive analytics and reporting tools to help you track the performance of your content and optimize your strategy over time.",
    },
];

const AccordionItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="py-7 border-b border-white/30 cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
            <div className="flex items-center">
                <span className="flex-1 sm:text-xl font-bold select-none">
                    {question}
                </span>
                {isOpen ? <MinusIcon /> : <PlusIcon />}
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        className={clsx("mt-4", { hidden: !isOpen, "": isOpen })}
                        initial={{ opacity: 0, height: 0, marginTop: 0 }}
                        animate={{ opacity: 1, height: "auto", marginTop: 16 }}
                        exit={{ opacity: 0, height: 0, marginTop: 0 }}
                    >
                        {answer}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export const FAQs = () => {
    return (
        <div className="bg-black text-white bg-gradient-to-b from-[#5D2CA8] to-black py-[72px] sm:py-24">
            <div className="container">
                <h2 className="text-center text-5xl sm:text-6xl font-bold tracking-tighter">Frequently Asked Questions</h2>
                <div className="mt-12 max-w-5xl mx-auto">
                    {items.map((item, index) => (
                        <AccordionItem key={index} question={item.question} answer={item.answer} />
                    ))}
                </div>
            </div>
        </div>
    );
};
