import React from 'react';
import { useNavigate } from 'react-router-dom';

const TemplateCard = ({ id, title, description, icon, color, badge }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/copywriter/${id}`);
  };

  return (
    <div
      className="relative bg-white p-6 rounded-lg shadow-md transform hover:scale-105 transition-transform duration-200 hover:shadow-lg cursor-pointer hover:bg-indigo-100 hover:text-indigo-900"
      onClick={handleClick}
    >
      {badge && (
        <span className="absolute top-2.5 right-2.5 bg-yellow-500 text-xs text-black px-2 py-0.5 rounded-full">
          {badge}
        </span>
      )}
      <div className="flex justify-between items-center mb-4">
        <div className="text-4xl">
          {icon}
        </div>
        <div className="text-xl transform rotate-45 text-gray-700 hover:text-indigo-900">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </div>
      <h2 className="text-lg font-bold text-gray-800 hover:text-indigo-900">{title}</h2>
      <p className="text-sm text-gray-600 hover:text-indigo-900">{description}</p>
    </div>
  );
};

export default TemplateCard;
