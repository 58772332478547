"use client";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

import appScreen from "../LandingPage/assets/images/app-screen.png";
import Image from 'react-bootstrap/Image';


export const ProductShowcase = () => {
    const appImage = useRef(null);

    const { scrollYProgress } = useScroll({
        target: appImage,
        offset: ["start end", "end end"],
    });

    const rotateX = useTransform(scrollYProgress, [0, 1], [15, 0]);
    const opacity = useTransform(scrollYProgress, [0, 1], [0.5, 1]);
    return (
        <div className="bg-black text-white bg-gradient-to-b from-black to-[#5D2CA8] py-72px sm:py-24">
            <div className="container">
                <h2 className="text-center text-5xl sm:text-6xl font-bold tracking-tighter">User-Friendly Interface</h2>
                <div className="max-w-3xl mx-auto">
                    <p className="text-center text-white/70 mt-5">
                        Experience the satisfaction of effortless content creation with our platform. Track your social media growth, stay motivated, and celebrate your successes—one post at a time.
                    </p>
                </div>
                <motion.div
                    style={{
                        opacity: opacity,
                        rotateX: rotateX,
                        transformPerspective: "800px",
                    }}
                >
                    <Image src={appScreen} alt="App screen" className="mt-14 mx-auto rounded-md shadow-2xl" ref={appImage} />
                </motion.div>
            </div>
        </div>
    );
};
