import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { templates } from './Copywriter';
import axios from 'axios';

const parseTextToHtml = (text) => {
    let html = text;

    html = html.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    html = html.replace(/\n/g, '<br/>');

    return html;
};

const TemplateEditor = () => {
    const { id } = useParams();
    const template = templates.find((t) => t.id === parseInt(id));

    const [language, setLanguage] = useState('English');
    const [creativity, setCreativity] = useState('Low');
    const [tone, setTone] = useState('Funny');
    const [results, setResults] = useState('1');
    const [tokenLength, setTokenLength] = useState(200);
    const [documentName, setDocumentName] = useState('New Document');
    const [content, setContent] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    if (!template) {
        return <p>Template not found</p>;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await axios.post('https://onetappost.com/api/chatCompletion', {
                prompt: description + " Note : Generate in " + language + " language" + " with " + creativity + " creativity" + " and " + tone + " tone" + " with " + results + " results" + " with " + tokenLength + " token length",
                system_prompt: template.title,
            });

            const { data } = response.data;


            const parsedHtml = parseTextToHtml(data);

            setContent(parsedHtml);
            setLoading(false);
        } catch (exception) {
            setError('Failed to generate content. Please try again.');
            setLoading(false);
        }
    };

    const handleClear = () => {
        setContent('');
        setDescription('');
        setDocumentName('New Document');
        setLanguage('English');
        setCreativity('Low');
        setTone('Funny');
        setResults('1');
        setTokenLength(200);
    };

    return (
        <div className="m-4 pt-6 pb-12 md:pb-6">
            <div className="bg-white shadow-md p-6 rounded-lg flex items-center justify-between mb-8">
                <div className="flex items-center">

                    <div>
                        <h1 className="text-xl font-bold text-gray-900">Content Customization</h1>
                        <p className="text-gray-600 text-sm">Create and personalize your content with ease</p>
                    </div>
                </div>
                <div>

                </div>
            </div>
            <div className="grid grid-cols-12 gap-7">
                <div className="col-span-12 lg:col-span-4">
                    <div className="rounded-xl bg-white shadow-card p-5">
                        <p className="text18 font-medium text-gray-900 mb-2">{template.title}</p>
                        <small className="text-gray-700">
                            {template.description}
                        </small>
                        <form className="flex flex-col gap-5 mt-5" onSubmit={handleSubmit}>
                            <div className="flex flex-col items-start w-full">
                                <small className="w-full mb-1 whitespace-nowrap flex items-center font-medium text-gray-500">
                                    <span className="mr-1">Language</span>
                                </small>
                                <div className="w-full">
                                    <div className="relative w-full">
                                        <select
                                            value={language}
                                            onChange={(e) => setLanguage(e.target.value)}
                                            className="text-left border border-gray-200 focus:border-primary-500 h-10 px-2.5 rounded-md focus:outline-none w-full text-sm"
                                        >
                                            <option value="English">English</option>

                                            <option value="हिंदी">
                                                हिंदी
                                            </option>
                                            <option value="Français" >
                                                Français
                                            </option>
                                            <option value="iq" >
                                                iq
                                            </option>
                                            <option value="Polish">
                                                Polish
                                            </option>
                                            <option value="Português Br" >
                                                Português Br
                                            </option>
                                            <option value="arabic">
                                                arabic
                                            </option>
                                            <option value="Indonesia" >
                                                Indonesia
                                            </option>
                                            <option value="hungarian" >
                                                hungarian
                                            </option>
                                            <option value="buga" >
                                                buga
                                            </option>
                                            <option value="Spanish" >
                                                Spanish
                                            </option>
                                            <option value="Vietnamese" >
                                                Vietnamese
                                            </option>
                                            <option value="Turkish" >
                                                Turkish
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="relative flex flex-col items-start w-full">
                                <small className="w-full mb-1 whitespace-nowrap flex items-center font-medium text-gray-500">
                                    <span className="mr-1">
                                        What is your {template.title} about?
                                    </span>
                                    <span className="block text-red-500">*</span>
                                </small>
                                <small className="absolute top-0 right-0 w-full text-end">0/400</small>
                                <div className="w-full">
                                    <textarea
                                        name="details"
                                        rows="4"
                                        cols="10"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        className="rounded-md w-full text-sm px-2.5 py-2 focus:ring-0 border-none outline outline-1 outline-gray-200 focus:outline-primary-500"
                                        placeholder={`e.g. Describe your ${template.title.toLowerCase()}`}
                                        required
                                        maxLength="400"
                                    ></textarea>
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-6">
                                <div className="flex flex-col items-start w-full">
                                    <small className="w-full mb-1 whitespace-nowrap flex items-center font-medium text-gray-500">
                                        <span className="mr-1">Creativity</span>
                                    </small>
                                    <div className="w-full">
                                        <div className="relative w-full">
                                            <select
                                                value={creativity}
                                                onChange={(e) => setCreativity(e.target.value)}
                                                className="text-left border border-gray-200 focus:border-primary-500 h-10 px-2.5 rounded-md focus:outline-none w-full text-sm"
                                            >
                                                <option value="Low">Low</option>
                                                <option value="Medium">Medium</option>
                                                <option value="High">High</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col items-start w-full">
                                    <small className="w-full mb-1 whitespace-nowrap flex items-center font-medium text-gray-500">
                                        <span className="mr-1">Tone of Voice</span>
                                    </small>
                                    <div className="w-full">
                                        <div className="relative w-full">
                                            <select
                                                value={tone}
                                                onChange={(e) => setTone(e.target.value)}
                                                className="text-left border border-gray-200 focus:border-primary-500 h-10 px-2.5 rounded-md focus:outline-none w-full text-sm"
                                            >
                                                <option value="Funny">Funny</option>
                                                <option value="Serious">Serious</option>
                                                <option value="Formal">Formal</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-6">
                                <div className="flex flex-col items-start w-full">
                                    <small className="w-full mb-1 whitespace-nowrap flex items-center font-medium text-gray-500">
                                        <span className="mr-1">Number of results</span>
                                    </small>
                                    <div className="w-full">
                                        <div className="relative w-full">
                                            <select
                                                value={results}
                                                onChange={(e) => setResults(e.target.value)}
                                                className="text-left border border-gray-200 focus:border-primary-500 h-10 px-2.5 rounded-md focus:outline-none w-full text-sm"
                                            >
                                                <option value="1">1</option>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col items-start w-full">
                                    <small className="w-full mb-1 whitespace-nowrap flex items-center font-medium text-gray-500">
                                        <span className="mr-1">Token Length</span>
                                        <span className="block text-red-500">*</span>
                                    </small>
                                    <div className="relative w-full">
                                        <input
                                            type="number"
                                            name="length"
                                            className="border border-gray-200 focus:border-primary-500 h-10 px-2.5 focus:outline-0 focus:ring-0 rounded-md w-full text-sm"
                                            required
                                            value={tokenLength}
                                            onChange={(e) => setTokenLength(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <button
                                type="submit"
                                className="bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white  hover:bg-gradient-to-r hover:from-[#16B197] hover:to-[#2091DC] font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none rounded-lg shadow-md hover:shadow-lg focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none block w-full capitalize bg-primary-500 text-sm px-5 mt-2 py-0 h-10"
                                disabled={loading}
                            >
                                {loading ? 'Generating...' : 'Submit'}
                            </button>
                        </form>
                    </div>
                </div>

                <div className="col-span-12 lg:col-span-8">
                    <div className="rounded-xl bg-white shadow-card">
                        <form onSubmit={handleSubmit}>
                            <div className="px-5 pt-5">
                                <div className="flex flex-col items-start md:flex-row md:items-center">
                                    <small className="max-w-[164px] w-full mb-1 whitespace-nowrap flex items-center font-medium text-gray-500">
                                        <span className="mr-1">Document Name</span>
                                        <span className="block text-red-500">*</span>
                                    </small>
                                    <div className="relative w-full">
                                        <input
                                            name="document_name"
                                            className="border border-gray-200 focus:border-primary-500 h-10 px-2.5 focus:outline-0 focus:ring-0 rounded-md w-full text-sm"
                                            required
                                            value={documentName}
                                            onChange={(e) => setDocumentName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <ReactQuill
                                value={content}
                                onChange={setContent}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ size: [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                        ['link',],
                                        ['clean']
                                    ],
                                    clipboard: {
                                        matchVisual: true,
                                    }
                                }}
                                style={{
                                    height: '400px',
                                    marginBottom: '40px',

                                }}
                                className="quill-editor"
                            />

                            {error && <p className="text-red-500">{error}</p>}

                            <div className="flex justify-end items-center p-2 md:p-5">
                                <button
                                    className="font-sans font-bold text-center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none rounded-lg border hover:opacity-75 focus:ring focus:ring-white/50 active:opacity-[0.85] capitalize border-primary-500 text-primary-500 text-sm ml-5 px-5 py-0 h-10"
                                    type="button"
                                    onClick={handleClear}
                                >
                                    Clear
                                </button>
                                <button
                                    className="flex items-center justify-center bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white rounded-md hover:bg-gradient-to-r hover:from-[#16B197] hover:to-[#2091DC] shadow-lg text-sm ml-5 px-5 py-0 h-10"
                                    type="button"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TemplateEditor;
