import React, { createContext, useState, useEffect } from 'react';
import { currentUser, login as loginUser, logout as logoutUser } from '../api/auth';
import { useNavigate } from 'react-router-dom';
import { analytics } from '../firebase';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const checkUser = async () => {
            setLoading(true);
            try {
                const userData = await currentUser();
                console.log(userData);
                if (!userData.data.error) {
                    setUser(userData);
                } else {
                    setUser(null);
                }
            } catch (error) {
                setUser(null);
            } finally {
                setLoading(false);
            }
        };
        checkUser();
    }, []);

    const login = async (email, password) => {
        const data = await loginUser(email, password);
        console.log(data)
        if (!data.errors) {
            localStorage.setItem('ai-tk', JSON.stringify(data.token));
            setUser(data.user);
            return data;
        }
        throw new Error(data.message || 'Login failed');
    };

    const logout = async () => {
        await logoutUser();
        localStorage.removeItem('ai-tk');
        setUser(null);
        navigate('/signin', { replace: true });
    };

    // After successful login or signup
    const setUserProperties = (user) => {
        analytics.setUserId(user.uid);
        analytics.setUserProperties({
            account_type: user.accountType,
            subscription_status: user.subscriptionStatus,
            user_role: user.role
        });
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
