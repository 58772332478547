import React, { useEffect } from 'react';
import { FaPodcast } from 'react-icons/fa';
import TemplateCard from './TemplateCard';
import { templates } from './Copywriter';
import GeneratedContentChart from './GeneratedPostGraph';
import { Link } from 'react-router-dom';
import { useAuth } from '../api/AuthContext';
import useAnalytics from '../hooks/useAnalytics';

const Dashboard = () => {
  const { user } = useAuth();
  const { logCustomEvent } = useAnalytics();

  useEffect(() => {
    logCustomEvent('dashboard_viewed', {
      user_id: user.data.id,
      user_type: user.data.userType
    });
  }, []);

  const handleTemplateClick = (templateId, templateName) => {
    logCustomEvent('template_selected', {
      template_id: templateId,
      template_name: templateName
    });
    // ... rest of the function ...
  };

  return (
    <div className="p-4 md:p-6 bg-gradient-to-r from-[#f8f9fa] to-[#f0f4f7] min-h-screen">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 md:mb-8">
        <div className="mb-4 md:mb-0">
          <h1 className="text-2xl md:text-3xl font-bold">OneTap Post Dashboard</h1>
          <p className="text-sm md:text-base text-muted-foreground">
            Welcome back, {user.data.name ?? user.data.email}. Here's your social media overview.
          </p>
        </div>

        <Link to='/describe'>
          <button className="bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white py-2 px-4 md:py-3 md:px-6 rounded-lg flex items-center shadow-lg transition-transform transform hover:scale-105">
            <FaPodcast className="mr-2" />
            <div>Generate Post</div>
          </button>
        </Link>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6 md:mb-8">
        <Card title="Total Followers" value="124.5K" change="+5.2%" />
        <Card title="Engagement Rate" value="3.2%" change="+0.8%" />
        <Card title="Scheduled Posts" value="18" change="-2" />
        <Card title="Total Comments" value="1,284" change="+152" />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-6 md:mb-8">
        <UpcomingPosts />
        <AIPoweredInsights />
      </div>

      <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg mb-6 md:mb-8">
        <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-4">Content Creation Over Time</h2>
        <p className="text-sm md:text-base text-gray-600">Graph of content created from month to month on this platform.</p>

        <GeneratedContentChart />
      </div>

      <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg mt-6">
        <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-4">Most Used Templates</h2>
        <p className="text-sm md:text-base text-gray-600">Save time and resources with intelligent writing assistance.</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
          {templates.map((template, index) => (
            <TemplateCard
              id={template.id}
              key={index}
              title={template.title}
              description={template.description}
              icon={template.icon}
              color={template.color}
              badge="Standard"
              onClick={() => handleTemplateClick(template.id, template.title)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const Card = ({ title, value, change }) => (
  <div className="border bg-card text-card-foreground bg-white p-4 rounded-lg shadow-lg">
    <div className="flex flex-row items-center justify-between pb-2">
      <h3 className="text-sm font-medium">{title}</h3>
      <Icon title={title} />
    </div>
    <div>
      <div className="text-xl md:text-2xl font-bold">{value}</div>
      <p className="text-xs text-muted-foreground">
        <span className={change.includes('-') ? 'text-red-600' : 'text-green-600'}>{change}</span> from last month
      </p>
    </div>
  </div>
);

const Icon = ({ title }) => {
  switch (title) {
    case 'Total Followers':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-users h-4 w-4 text-muted-foreground"
        >
          <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
          <circle cx="9" cy="7" r="4"></circle>
          <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
        </svg>
      );
    case 'Engagement Rate':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-trending-up h-4 w-4 text-muted-foreground"
        >
          <polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline>
          <polyline points="16 7 22 7 22 13"></polyline>
        </svg>
      );
    case 'Scheduled Posts':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-calendar h-4 w-4 text-muted-foreground"
        >
          <path d="M8 2v4"></path>
          <path d="M16 2v4"></path>
          <rect width="18" height="18" x="3" y="4" rx="2"></rect>
          <path d="M3 10h18"></path>
        </svg>
      );
    case 'Total Comments':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-message-square h-4 w-4 text-muted-foreground"
        >
          <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
        </svg>
      );
    default:
      return null;
  }
};

const UpcomingPosts = () => (
  <div className="border bg-card text-card-foreground bg-white p-4 rounded-lg shadow-lg">
    <div className="flex flex-col space-y-1.5">
      <h3 className="font-semibold leading-none tracking-tight">Upcoming Posts</h3>
      <p className="text-sm text-muted-foreground">Scheduled for today</p>
    </div>
    <div className="pt-2">
      <ul className="space-y-4">
        <PostItem platform="Instagram" time="2:00 PM" content="New product launch!" />
        <PostItem platform="Twitter" time="4:30 PM" content="Join our live Q&A session" />
        <PostItem platform="Facebook" time="7:00 PM" content="Behind the scenes look" />
        <PostItem platform="LinkedIn" time="9:00 AM" content="Industry insights report" />
      </ul>
    </div>
    <div className="pt-4">
      <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 w-full">
        View All Scheduled Posts
      </button>
    </div>
  </div>
);

const PostItem = ({ platform, time, content }) => (
  <li className="flex items-center space-x-4">
    <div className="bg-secondary p-2 rounded-full">
      <PlatformIcon platform={platform} />
    </div>
    <div className="flex-1">
      <p className="font-medium">{content}</p>
      <p className="text-sm text-muted-foreground">{platform}</p>
    </div>
    <div className="inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-foreground">
      {time}
    </div>
  </li>
);

const PlatformIcon = ({ platform }) => {
  switch (platform) {
    case 'Instagram':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="h-4 w-4"
        >
          <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
          <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
          <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"></line>
        </svg>
      );
    case 'Twitter':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="h-4 w-4"
        >
          <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
        </svg>
      );
    case 'Facebook':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="h-4 w-4"
        >
          <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
        </svg>
      );
    case 'LinkedIn':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="h-4 w-4"
        >
          <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
          <rect width="4" height="12" x="2" y="9"></rect>
          <circle cx="4" cy="4" r="2"></circle>
        </svg>
      );
    default:
      return null;
  }
};

const AIPoweredInsights = () => (
  <div className="border bg-card text-card-foreground bg-white p-4 rounded-lg shadow-lg">
    <div className="flex flex-col space-y-1.5">
      <h3 className="font-semibold leading-none tracking-tight">AI-Powered Insights</h3>
      <p className="text-sm text-muted-foreground">Personalized recommendations for your social media strategy</p>
    </div>
    <div className="pt-2">
      <ul className="space-y-4">
        <InsightItem content="Your engagement rate is 20% higher on posts with images." />
        <InsightItem content="Tweets with hashtags receive 35% more retweets on average." />
        <InsightItem content="Your Facebook audience is most active between 7-9 PM." />
        <InsightItem content="LinkedIn posts about industry trends get 2x more comments." />
      </ul>
    </div>
    <div className="pt-4">
      <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 w-full">
        Generate More Insights
      </button>
    </div>
  </div>
);

const InsightItem = ({ content }) => (
  <li className="flex items-start space-x-2">
    <div className="mt-0.5 bg-primary/20 p-1 rounded-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="h-3 w-3 text-primary"
      >
        <polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline>
        <polyline points="16 7 22 7 22 13"></polyline>
      </svg>
    </div>
    <p className="text-sm">{content}</p>
  </li>
);

export default Dashboard;
