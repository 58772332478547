import React, { useState } from 'react'
import MyCalendar from '../Components/BigCalendar'
import { useLocation } from 'react-router-dom';

const Schdueler = (event) => {
  const location = useLocation();
  const { state } = location;
  const events = state ? state.events : [];
  return (
    <div className='mt-10'><MyCalendar  events={events}/></div>
  )
}

export default Schdueler