import React from 'react';
import { FaRegComment, FaRetweet, FaHeart, FaChartBar, FaShareSquare } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

function TwitterBigPost({image, caption}) {
  // const location = useLocation()
  // const {image, caption} = location.state || {}
 
  return (
    <div className="max-w-md mx-auto bg-white border rounded-lg shadow-md p-4 mt-2">
      <div className="flex items-center mb-4">
        <img
          className="w-10 h-10 rounded-full"
          src="https://via.placeholder.com/150"
          alt="Elon Musk"
        />
        <div className="ml-3">
          <div className="flex items-center">
            <span className="font-bold text-gray-800">Elon Musk</span>
            <span className="text-blue-500 ml-1">@elonmusk</span>
            <span className="text-gray-500 ml-1">• Aug 17</span>
          </div>
        </div>
      </div>
      <p className="text-gray-800 mb-4">
        {caption}
      </p>
      <div>
        <img 
        src={image}
        alt='twitter'
        />
      </div>
      <div className="flex justify-between mt-4 text-gray-500">
        <div className="flex items-center space-x-1">
          <FaRegComment />
          <span>10K</span>
        </div>
        <div className="flex items-center space-x-1">
          <FaRetweet />
          <span>34K</span>
        </div>
        <div className="flex items-center space-x-1">
          <FaHeart />
          <span>356K</span>
        </div>
        <div className="flex items-center space-x-1">
          <FaChartBar />
          <span>67M</span>
        </div>
        <div className="flex items-center space-x-1">
          <FaShareSquare />
        </div>
      </div>
    </div>
  );
}

export default TwitterBigPost;
