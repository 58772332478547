import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SparklesText from '../Components/SparkleTextInstall'; // Ensure this import is correct
import { FaUserCircle } from 'react-icons/fa';
import { TextRevealDemo } from '../Components/AnimationTyping';
import TypingAnimation from '../Components/AnimatedTextInstall';

const DescribePost = () => {
    const navigate = useNavigate();
    const [input, setInput] = useState('');
    const [dataResponse, setDataResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isLottie, setIsLottie] = useState(true);

    const [selectedTone, setSelectedTone] = useState(null);
    const [selectedCreativity, setSelectedCreativity] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('English');
  
    const toneOptions = [
      'Professional', 'Funny', 'Casual', 'Excited', 'Witty', 'Sarcastic', 
      'Dramatic', 'Feminine', 'Masculine', 'Grumpy', 'Bold', 'Secretive', 'Poetic'
    ];
  
    const creativityOptions = [
      'Minimal', 'Subtle', 'Basic', 'Modest', 'Adequate', 'Balanced', 
      'Intermediate', 'Enriched', 'Expressive', 'Imaginative', 'Creative', 
      'Innovative', 'Inspired', 'Visionary', 'Inventive', 'Pioneering', 
      'Artistic', 'Radical', 'Genius', 'Transcendent', 'Boundless'
    ];
  
    const languageOptions = [
      'English', 'Spanish', 'French', 'German', 'Chinese', 'Japanese', 
      'Korean', 'Portuguese', 'Russian', 'Arabic', 'Hindi', 'Italian', 
      'Dutch', 'Polish', 'Turkish', 'Vietnamese', 'Thai', 'Greek', 
      'Hebrew', 'Swedish', 'Norwegian', 'Danish', 'Finnish', 'Czech', 
      'Hungarian', 'Romanian', 'Slovak', 'Indonesian', 'Malay', 'Filipino',
      'Bengali', 'Urdu', 'Punjabi', 'Tamil', 'Telugu', 'Marathi', 'Gujarati',
      'Malayalam', 'Kannada'
    ];

    const languageScriptMap = {
        English: 'Latin',
        Spanish: 'Latin',
        French: 'Latin',
        German: 'Latin',
        Chinese: 'Hanzi',
        Japanese: 'Katakana',
        Korean: 'Hangul',
        Portuguese: 'Latin',
        Russian: 'Cyrillic',
        Arabic: 'Arabic',
        Hindi: 'Devanagari',
        Italian: 'Latin',
        Dutch: 'Latin',
        Polish: 'Latin',
        Turkish: 'Latin',
        Vietnamese: 'Latin',
        Thai: 'Thai',
        Greek: 'Greek',
        Hebrew: 'Hebrew',
        Swedish: 'Latin',
        Norwegian: 'Latin',
        Danish: 'Latin',
        Finnish: 'Latin',
        Czech: 'Latin',
        Hungarian: 'Latin',
        Romanian: 'Latin',
        Slovak: 'Latin',
        Indonesian: 'Latin',
        Malay: 'Latin',
        Filipino: 'Latin',
        Bengali: 'Bengali',
        Urdu: 'Arabic',
        Punjabi: 'Gurmukhi',
        Tamil: 'Tamil',
        Telugu: 'Telugu',
        Marathi: 'Devanagari',
        Gujarati: 'Gujarati',
        Malayalam: 'Malayalam',
        Kannada: 'Kannada',
    };
    
  
    const handleToneClick = (tone) => {
      setSelectedTone(tone);
    };
  
    const handleCreativityClick = (creativity) => {
      setSelectedCreativity(creativity);
    };

    const handleSend = async () => {
        if (!input.trim()) return;
        setLoading(true);
        setIsLottie(false);
        const selectedScript = languageScriptMap[selectedLanguage] ;
        try {
            const result = await fetch('https://onetappost.com/api/generatePostData', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('ai-tk')}`,
                },
                body: JSON.stringify({
                    description: input + 
                    ` You will reply in ${selectedLanguage} language using the ${selectedScript} script.` +
                    ` Your tone of reply will be ${selectedTone}.` +
                    ` Your reply creativity will be ${selectedCreativity}.` +
                    'Add relevant emojis'
                   
                }),
            });

            if (!result.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await result.json();
            setDataResponse(data.data);
            console.log(data.data)
        } catch (error) {
            console.error('Error saving response:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && input.trim()) {
            handleSend();
        }
    };

    return (
        <div className="max-w-screen-lg mx-auto px-4 py-8 sm:px-6 sm:py-10"> {/* Responsive padding */}
            <TypingAnimation />
            
            <div className="mb-8">
                <label className="font-semibold text-lg block mb-2" htmlFor="post-description">
                    Describe your post<span className="text-red-500">*</span>
                </label>
                <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
                    <input
                        id="post-description"
                        type="text"
                        placeholder="Type your text here"
                        className="flex-1 w-full border border-gray-300 p-3 rounded-lg focus:ring-2 focus:ring-[#2093D6] focus:outline-none"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                </div>
            </div>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Language</label>         
                <select 
                    value={selectedLanguage} 
                    onChange={(e) => setSelectedLanguage(e.target.value)} 
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                >
                    {languageOptions.map((language) => (
                        <option key={language} value={language}>
                            {language}
                        </option>
                    ))}
                </select>
            </div>

            <div className="mb-4">
                <label className="block text-lg font-medium text-gray-700">Tone of voice</label>
                <div className="mt-2 flex flex-wrap gap-2">
                    {toneOptions.map((tone) => (
                        <button
                            key={tone}
                            onClick={() => handleToneClick(tone)}
                            className={`px-2 py-1 text-sm font-semibold border rounded-md ${selectedTone === tone ? 'bg-black text-white' : 'bg-white text-gray-700 border-gray-300'}`}
                        >
                            {tone}
                        </button>
                    ))}
                </div>
            </div>

            <div className="mb-4">
                <label className="block text-lg font-medium text-gray-700">Creativity</label>
                <div className="mt-2 flex flex-wrap gap-2">
                    {creativityOptions.map((creativity) => (
                        <button
                            key={creativity}
                            onClick={() => handleCreativityClick(creativity)}
                            className={`px-2 py-1 text-sm font-semibold border rounded-md ${selectedCreativity === creativity ? 'bg-black text-white' : 'bg-white text-gray-700 border-gray-300'}`}
                        >
                            {creativity}
                        </button>
                    ))}
                </div>
            </div>

            <div className='flex justify-center items-center mt-10'>
                <button
                    className={`bg-gradient-to-r from-[#16ACA0] to-[#2093D6] px-10 sm:px-20 py-3 w-full sm:w-auto text-white font-semibold rounded-lg shadow-md transform transition-transform ${!input.trim() ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105'}`}
                    onClick={handleSend}
                    disabled={!selectedTone || !selectedCreativity} 
                >
                    <SparklesText className="text-white text-xl" text="Generate" />
                </button>
            </div>

            <div className="mt-10">
                {isLottie ? (
                    <div className="flex flex-col items-center">
                        <iframe
                            className="h-72 w-72"
                            src="https://lottie.host/embed/69d58130-df4c-49c4-9047-48ba1aacb097/xFGDHKOb2p.json"
                            title="Lottie Animation"
                        ></iframe>
                        <h2 className="text-gray-400 text-center mt-4">
                            No posts here yet. Click on "Create" to generate something exciting!
                        </h2>
                    </div>
                ) : loading ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {[...Array(3)].map((_, i) => (
                            <div key={i} className="animate-pulse bg-gray-200 h-64 rounded-lg"></div>
                        ))}
                    </div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {dataResponse &&
                            dataResponse.map((item, index) => (
                                <div
                                    key={index}
                                    className="cursor-pointer bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
                                    onClick={() =>
                                        navigate("/posteditor", {
                                            state: { image: item.image_url, caption: item.caption },
                                        })
                                    }
                                >
                                    <div className="flex items-center mb-4">
                                        <FaUserCircle className="text-gray-400" size={24} />
                                        <span className="ml-4 flex-1 h-6 bg-gray-300 rounded-md"></span>
                                    </div>
                                    <div className="mb-4">
                                        <p className="text-gray-700">{item.caption}</p>
                                    </div>
                                    <div className="image-container">
                                        <img
                                            className="w-full h-48 object-cover rounded-lg"
                                            src={item.image_url}
                                            alt={`Image ${index + 1}`}
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DescribePost;
