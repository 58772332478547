import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';

function Session() {
  const [sessions, setSessions] = useState({});
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const handleNewSession = () => {
    const sessionId = `session-${Date.now()}`;
    setSessions({ ...sessions, [sessionId]: [] });
    setCurrentSessionId(sessionId);
  };

  const handleAddResponse = (response) => {
    setSessions({
      ...sessions,
      [currentSessionId]: [...sessions[currentSessionId], response],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValue) {
      handleAddResponse(inputValue);
      setInputValue('');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex">
      {/* Left Pane */}
      <div className="w-1/3 p-4 bg-white shadow-md">
        <div className="mb-4">
          <div className="flex justify-between mb-4">
            <h2 className="text-lg font-bold">Sessions</h2>
            <button
              onClick={handleNewSession}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            >
              <FaPlus /> New Session
            </button>
          </div>
          <div className="flex overflow-x-auto">
            {Object.keys(sessions).map((sessionId) => (
              <button
                key={sessionId}
                onClick={() => setCurrentSessionId(sessionId)}
                className={`px-4 py-2 rounded-lg mr-2 ${
                  currentSessionId === sessionId
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200'
                }`}
              >
                {sessionId}
              </button>
            ))}
          </div>
        </div>
        {currentSessionId && (
          <form onSubmit={handleSubmit} className="mb-4">
            <h2 className="text-lg font-bold">Tell AI what to write about *</h2>
            <textarea
              className="w-full p-2 border border-gray-300 rounded-lg mb-4"
              placeholder="e.g., Write about a social media automation tool for entrepreneurs and agencies"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded-lg w-full"
            >
              Run it
            </button>
          </form>
        )}
      </div>

      {/* Right Pane */}
      <div className="w-2/3 p-4 bg-white shadow-md">
        {currentSessionId ? (
          sessions[currentSessionId].length === 0 ? (
            <p className="text-center text-gray-500">Nothing here yet.</p>
          ) : (
            <div>
              {sessions[currentSessionId].map((response, index) => (
                <div
                  key={index}
                  className="bg-gray-100 p-4 mb-2 rounded-lg shadow"
                >
                  <p>{response}</p>
                </div>
              ))}
            </div>
          )
        ) : (
          <p className="text-center text-gray-500">No session selected</p>
        )}
      </div>
    </div>
  );
}

export default Session;
