import React from 'react'
import TwitterImagePost from './TwitterPost'
import FacebookPost from './FacebookPost'
import InstagramPost from './InstagramPost'
import LinkedInPost from './LinkedInPost'
import PinterestPost from './PinteresPost'
import TwitterBigPost from './TwitterBigPost'
import FacebookBigPost from './FacebookBigPost'
import InstagramBigPost from './InstagramBigPost'
import LinkedInBigPost from './LinkedInBigPost'
import PinterestBigPost from './PinterestBigPost'

const PostContainer = (image, caption) => {
  return (
    <div className='mt-6 gap-4 grid grid-cols-2 '>
    <TwitterImagePost image={image} caption={caption}/>
    <FacebookPost image={image} caption={caption}/>
    <InstagramPost image={image} caption={caption}/>
    <LinkedInPost image={image} caption={caption}/>
    <PinterestPost image={image} caption={caption}/>
    <TwitterBigPost />
    <FacebookBigPost />
    <InstagramBigPost />
    <LinkedInBigPost />
    <PinterestBigPost />
    </div>
  )
}

export default PostContainer