import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../api/AuthContext';
import { ClipLoader } from 'react-spinners';

const ProtectedRoute = ({ children }) => {
    const { user, loading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading && !user) {
            navigate('/signin', { replace: true });
        }
    }, [user, loading, navigate]);

    if (loading || !user) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                <ClipLoader color="#2130B7" loading={true} size={50} />
            </div>
        );
    }

    return children;
};

export default ProtectedRoute;
