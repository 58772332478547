import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateTimePicker = ({ onClose, onSave }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleTimeChange = (e) => {
        const [hours, minutes] = e.target.value.split(':');
        const newDate = new Date(selectedDate);
        newDate.setHours(hours);
        newDate.setMinutes(minutes);
        setSelectedDate(newDate);
    };

    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const setDayPeriod = (period) => {
        const newDate = new Date(selectedDate);
        switch (period) {
            case 'morning':
                newDate.setHours(9, 0);
                break;
            case 'midday':
                newDate.setHours(12, 0);
                break;
            case 'noon':
                newDate.setHours(15, 0);
                break;
            case 'evening':
                newDate.setHours(18, 0);
                break;
            default:
                break;
        }
        setSelectedDate(newDate);
    };

    const setRelativeDate = (days) => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + days);
        setSelectedDate(newDate);
    };

    const setRelativeTime = (hours) => {
        const newDate = new Date(selectedDate);
        newDate.setHours(newDate.getHours() + hours);
        setSelectedDate(newDate);
    };

    return (
        <div className="w-full max-w-sm mx-auto bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-lg font-semibold mb-4">Pick date and time</h2>

            <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="MMMM d, yyyy"
                className="w-full text-center py-2 mb-4 border rounded-lg"
                minDate={new Date()} // Prevents selecting a back date
            />

            <div className="grid grid-cols-2 gap-2 mb-4">
                <button
                    onClick={() => setRelativeDate(1)}
                    className="bg-gray-100 px-4 py-2 rounded-lg hover:bg-gray-200"
                >
                    Tomorrow
                </button>
                <button
                    onClick={() => setRelativeDate(2)}
                    className="bg-gray-100 px-4 py-2 rounded-lg hover:bg-gray-200"
                >
                    In 2 days
                </button>
            </div>

            <div className="grid grid-cols-4 gap-2 mb-4">
                <button
                    onClick={() => setDayPeriod('morning')}
                    className="bg-green-100 p-2 rounded-lg flex flex-col items-center hover:bg-green-200"
                >
                    <span role="img" aria-label="morning">🌅</span>
                    <p className="text-xs mt-1">Morning</p>
                </button>
                <button
                    onClick={() => setDayPeriod('midday')}
                    className="bg-green-100 p-2 rounded-lg flex flex-col items-center hover:bg-green-200"
                >
                    <span role="img" aria-label="midday">🌞</span>
                    <p className="text-xs mt-1">Midday</p>
                </button>
                <button
                    onClick={() => setDayPeriod('noon')}
                    className="bg-green-100 p-2 rounded-lg flex flex-col items-center hover:bg-green-200"
                >
                    <span role="img" aria-label="noon">🏙️</span>
                    <p className="text-xs mt-1">Noon</p>
                </button>
                <button
                    onClick={() => setDayPeriod('evening')}
                    className="bg-green-100 p-2 rounded-lg flex flex-col items-center hover:bg-green-200"
                >
                    <span role="img" aria-label="evening">🌜</span>
                    <p className="text-xs mt-1">Evening</p>
                </button>
            </div>

            <div className="flex justify-center mb-4">
                <input
                    type="time"
                    className="text-center w-24 py-2 border rounded-lg"
                    value={formatTime(selectedDate)}
                    onChange={handleTimeChange}
                />
            </div>

            <div className="grid grid-cols-3 gap-2 mb-4">
                <button
                    onClick={() => setRelativeTime(0.5)}
                    className="bg-gray-100 px-4 py-2 rounded-lg hover:bg-gray-200"
                >
                    In 30min
                </button>
                <button
                    onClick={() => setRelativeTime(1)}
                    className="bg-gray-100 px-4 py-2 rounded-lg hover:bg-gray-200"
                >
                    In 1h
                </button>
                <button
                    onClick={() => setRelativeTime(2)}
                    className="bg-gray-100 px-4 py-2 rounded-lg hover:bg-gray-200"
                >
                    In 2h
                </button>
            </div>

            <div className="flex justify-between">
                <button
                    onClick={onClose}
                    className="w-full mr-2 bg-gray-200 text-gray-700 font-semibold py-2 rounded-lg hover:bg-gray-300"
                >
                    Cancel
                </button>
                <button
                    onClick={() => onSave(selectedDate)}
                    className="w-full ml-2 bg-green-500 text-white font-semibold py-2 rounded-lg hover:bg-green-600"
                >
                    Done
                    
                </button>
            </div>
        </div>
    );
};

export default DateTimePicker;
