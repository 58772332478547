import React, { useEffect, useState, useCallback, useMemo } from "react";
import { FaUser, FaFacebook, FaInstagram, FaTicketAlt, FaSignOutAlt, FaBars, FaTimes } from 'react-icons/fa';
import { currentUser, logout } from "../api/auth";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import Profile from "./Profiles";
import { useAuth } from "../api/AuthContext";
import HelpPage from "./Help";

const SidebarButton = React.memo(({ icon: Icon, label, isActive, onClick }) => (
  <button
    className={`flex items-center w-full p-3 rounded-lg transition-colors duration-200 ${isActive ? "bg-blue-50 text-blue-700 font-semibold" : "text-gray-700 hover:bg-gray-100"
      }`}
    onClick={onClick}
  >
    <Icon className="mr-3" /> {label}
  </button>
));

const InputField = React.memo(({ label, type, value, onChange, placeholder }) => (
  <div>
    <label className="block text-gray-700 text-sm font-semibold mb-2">{label}</label>
    <input
      className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200"
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  </div>
));

const PersonalInfo = React.memo(({ userData, onInputChange }) => (
  <div className="p-6 space-y-6">
    <h2 className="text-2xl font-semibold mb-4">Personal Information</h2>
    <InputField label="Full Name" type="text" value={userData.name} onChange={onInputChange('name')} />
    <InputField label="Email" type="email" value={userData.email} onChange={onInputChange('email')} />
    <InputField label="Phone Number" type="text" placeholder="+1 234-567-8912" />
    <button className="bg-gradient-to-r from-[#16B197] to-[#2091DC] w-full transition-all duration-200 text-white px-4 py-3 rounded-lg hover:shadow-lg">
      Save Changes
    </button>
  </div>
));

const UserProfile = () => {
  const { section } = useParams();
  const [userData, setUserData] = useState({ name: "", email: "", profilePic: "" });
  const { user } = useAuth();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const navigate = useNavigate();
  const [insta_accessToken, setInsta_accessToken] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false); // For sidebar toggle on mobile

  const fetchDetails = useCallback(async () => {
    try {
      const response = await currentUser();
      setUserData(response.data);
      setInsta_accessToken(response.data.insta_accessToken);
      setIsDataLoaded(true);
    } catch (error) {
      console.error("Failed to fetch user details:", error);
    }
  }, []);

  useEffect(() => {
    if (!isDataLoaded) {
      fetchDetails();
    }
  }, [isDataLoaded, fetchDetails]);

  const handleInputChange = useCallback((field) => (e) => {
    setUserData(prev => ({ ...prev, [field]: e.target.value }));
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Failed to logout:", error);
    }
  }, [navigate]);

  const sidebarItems = useMemo(() => [
    { icon: FaUser, label: "Personal Info", key: "personalInfo" },
    { icon: FaFacebook, label: "Social Accounts", key: "socialAccounts" },
    { icon: FaInstagram, label: "Support", key: "support" },
    { icon: FaTicketAlt, label: "Tickets", key: "tickets" },
  ], []);

  const renderSectionContent = useMemo(() => {
    switch (section) {
      case "personalInfo":
        return <PersonalInfo userData={userData} onInputChange={handleInputChange} />;
      case "socialAccounts":
        return <Profile insta_accessToken={insta_accessToken} setInsta_accessToken={setInsta_accessToken} />;
      case "support":
        return (
          <div className="p-6">
            <HelpPage />
          </div>
        );
      case "tickets":
        return (
          <div className="p-6">
            <h2 className="text-2xl font-semibold mb-4">Tickets</h2>
            <p className="text-gray-700">You have no open tickets at the moment.</p>
          </div>
        );
      default:
        return <Navigate to="/user/personalInfo" replace />;
    }
  }, [section, userData, handleInputChange, insta_accessToken]);

  const sidebar = useMemo(() => (
    <div className={`fixed lg:static z-40 top-0 left-0 w-64 h-full bg-white shadow-lg p-6 flex flex-col transition-transform duration-300 ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0`}>
      <ul className="space-y-4 flex-grow">
        {sidebarItems.map((item) => (
          <li key={item.key}>
            <SidebarButton
              icon={item.icon}
              label={item.label}
              isActive={section === item.key}
              onClick={() => {
                setSidebarOpen(false); // Close sidebar on mobile when clicked
                navigate(`/user/${item.key}`);
              }}
            />
          </li>
        ))}
      </ul>
      <button
        className="flex items-center w-full p-3 mt-auto rounded-lg text-red-600 hover:bg-red-50 transition-colors duration-200"
        onClick={handleLogout}
      >
        <FaSignOutAlt className="mr-3" /> Logout
      </button>
    </div>
  ), [sidebarItems, section, navigate, handleLogout, sidebarOpen]);

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar Toggle Button for mobile */}
      <button
        className="fixed z-50 p-2 bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white rounded-md lg:hidden"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        {sidebarOpen ? <FaTimes /> : <FaBars />}
      </button>

      {sidebar}

      {/* Content Section */}
      <div className="flex-1 bg-white shadow-lg rounded-lg overflow-y-auto m-6">
        {renderSectionContent}
      </div>
    </div>
  );
};

export default React.memo(UserProfile);
