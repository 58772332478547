import { Feature } from "./Feature";

const features = [
    {
        title: "AI Post Generation",
        description:
            "Leverage cutting-edge AI to create engaging and effective social media content in seconds.",
    },
    {
        title: "Smart Post Scheduling",
        description:
            "Plan and automate your social media calendar with ease, ensuring optimal engagement at the right times.",
    },
    {
        title: "AI-Powered Tools",
        description:
            "Supercharge your workflow with AI-driven tools that enhance creativity and streamline your content creation.",
    },
    {
        title: "Idea Lab",
        description:
            "Fuel your creativity with our Idea Lab, where innovative concepts for your next big campaign are born.",
    },
    {
        title: "Copywriter Assistant",
        description:
            "Craft compelling copy effortlessly with AI that understands your brand voice and resonates with your audience.",
    },
    {
        title: "Analytics Dashboard",
        description:
            "Gain deep insights into your content’s performance and make data-driven decisions to boost your strategy.",
    },
];

export const Features = () => {
    return (
        <div className="bg-black text-white py-18 sm:py-24">
            <div className="container">
                <h2 className="text-center font-bold text-5xl sm:text-6xl tracking-tighter">Everything you need</h2>
                <div className="max-w-xl mx-auto">
                    <p className="text-center mt-5 text-xl text-white/70">
                        Create, schedule, and publish posts with ease. Collaborate with your team, automate your content strategy, and track performance—all in one seamless platform.
                    </p>
                </div>
                <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {features.map((feature, index) => (
                        <Feature key={index} title={feature.title} description={feature.description} />
                    ))}
                </div>
            </div>
        </div>
    );
};
