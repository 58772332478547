import React, { useState, useCallback } from 'react';
import { FaUser, FaFemale, FaBox, FaPalette, FaImage, FaFont, FaPaw, FaHamburger, FaUpload, FaLock } from 'react-icons/fa';
import useAnalytics from '../hooks/useAnalytics';



const AIModelTrainer = () => {
    const [modelName, setModelName] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [uploadedImages, setUploadedImages] = useState([]);
    const [tutorialTab, setTutorialTab] = useState('Person');

    const { logCustomEvent } = useAnalytics();


    const subjectTypes = [
        { name: 'Man', icon: FaUser },
        { name: 'Woman', icon: FaFemale },
        { name: 'Product', icon: FaBox },
        { name: 'Style', icon: FaPalette },
        { name: 'Object', icon: FaImage },
        { name: 'Font', icon: FaFont },
        { name: 'Pet', icon: FaPaw },
        { name: 'Food', icon: FaHamburger },
    ];

    const handleImageUpload = useCallback((e) => {
        const files = Array.from(e.target.files);
        setUploadedImages(prevImages => [...prevImages, ...files].slice(0, 20));
        logCustomEvent('ai_portrait_images_uploaded', { image_count: files.length });
    }, []);

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = Array.from(e.dataTransfer.files);
        setUploadedImages(prevImages => [...prevImages, ...files].slice(0, 20));
    }, []);

    const handleTrainModel = () => {
        logCustomEvent('ai_portrait_model_trained', {
            model_name: modelName,
            subject_type: selectedType,
            image_count: uploadedImages.length
        });
        // ... rest of the function ...
    };

    return (
        <div className="flex flex-col lg:flex-row min-h-screen bg-gray-100 text-gray-800 p-4">
            {/* Left Section - Model Creation Form */}
            <div className="w-full lg:w-1/2 p-4">
                <div className="bg-white rounded-lg shadow-md p-6">
                    <h1 className="text-3xl font-bold mb-2 text-[#414962]">Train Model</h1>
                    <p className="text-gray-500 mb-6">Choose a name, type, and upload 10-20 photos to get started the more the better.</p>

                    <div className="mb-6">
                        <label className="block mb-2 font-medium">Name</label>
                        <p className="text-gray-500 text-sm mb-2">Give your model a name so you can easily identify it later.</p>
                        <input
                            type="text"
                            value={modelName}
                            onChange={(e) => setModelName(e.target.value)}
                            placeholder="e.g. Natalie Headshots"
                            className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-[#16B197]"
                        />
                    </div>

                    <div className="mb-6">
                        <label className="block mb-2 font-medium">Subject Type</label>
                        <p className="text-gray-500 text-sm mb-2">Select the type of subject you want to train on.</p>
                        <div className="grid grid-cols-4 gap-2">
                            {subjectTypes.map((type) => (
                                <button
                                    key={type.name}
                                    onClick={() => setSelectedType(type.name)}
                                    className={`flex flex-col items-center justify-center p-2 rounded-lg border ${selectedType === type.name ? 'border-[#16B197] bg-[#E8F0FF]' : 'border-gray-300 hover:bg-gray-100'
                                        }`}
                                >
                                    <type.icon className="text-2xl mb-1 text-[#414962]" />
                                    <span className="text-xs">{type.name}</span>
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="mb-6">
                        <label className="block mb-2 font-medium">Samples</label>
                        <p className="text-gray-500 text-sm mb-2">Upload 4-20 images of the subject you want to train for.</p>
                        <div
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            className="border-2 border-dashed border-gray-300 rounded-lg p-4 text-center cursor-pointer hover:border-[#16B197]"
                        >
                            <FaUpload className="mx-auto text-3xl mb-2 text-[#414962]" />
                            <p>Drop images here, or click to select.</p>
                            <input
                                type="file"
                                multiple
                                onChange={handleImageUpload}
                                className="hidden"
                                id="image-upload"
                            />
                        </div>
                        {uploadedImages.length > 0 && (
                            <p className="mt-2 text-sm">{uploadedImages.length} images uploaded</p>
                        )}
                    </div>

                    <button className="w-full bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white font-bold py-2 px-4 rounded-lg flex items-center justify-center hover:opacity-90 transition-opacity">
                        <FaLock className="mr-2" />
                        Unlock Training
                    </button>
                    <p className="text-center text-[#2091DC] mt-2 text-sm">Upgrade to train a new model.</p>

                    <div className="mt-6">
                        <h3 className="text-xl font-bold mb-2 text-[#414962]">Important Details</h3>
                        <ul className="list-disc list-inside space-y-1 text-sm text-gray-600">
                            <li>Training usually takes between 20 to 40 minutes.</li>
                            <li>When your model is ready, we'll send you an email.</li>
                            <li>No nudes / NSFW images allowed.</li>
                        </ul>
                    </div>

                    <div className="mt-6">
                        <h3 className="text-xl font-bold mb-2 text-[#414962]">Tips</h3>
                        <ul className="list-disc list-inside space-y-2 text-sm text-gray-600">
                            <li><strong>Use Square Samples:</strong> For the best results, upload images with a 1:1 aspect ratio (square).</li>
                            <li><strong>Use Multiple Zoom Levels:</strong> Upload 10-20 high-quality photos of the person, object, or style you want to train on. If training a person, we recommend using 10 close-ups (face only), 5 mid-shots (from the chest up), and 3 full-body shots.</li>
                            <li><strong>Add Variety:</strong> Change up poses, backgrounds, and where the subject is looking. This makes your model better.</li>
                        </ul>
                        <p className="mt-4 text-sm text-gray-600">You'll be more likely to get a good model by uploading high quality samples.</p>
                        <p className="mt-2 text-sm text-gray-600">Keep in mind there is no single best way to train a great model, it may take some experimentation to get the results.</p>
                    </div>
                </div>
            </div>

            {/* Right Section - Tutorial */}
            <div className="w-full lg:w-1/2 p-4 mt-4 lg:mt-0">
                <div className="bg-white rounded-lg shadow-md p-6">
                    <h2 className="text-2xl font-bold mb-4 text-[#414962]">Tutorial</h2>
                    <div className="flex flex-wrap gap-2 mb-4">
                        {['Person', 'Product', 'Style', 'Pet'].map((tab) => (
                            <button
                                key={tab}
                                onClick={() => setTutorialTab(tab)}
                                className={`px-4 py-2 rounded-full ${tutorialTab === tab ? 'bg-gradient-to-r from-[#16B197] to-[#2091DC] text-white' : 'bg-gray-200 hover:bg-gray-300 text-gray-700'
                                    }`}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>

                    <h3 className="text-xl font-bold mb-2 text-[#414962]">How to get results:</h3>

                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 mb-4">
                        {['/final.png', '/final.png', '/final.png', '/final.png'].map((src, index) => (
                            <img key={index} src={src} alt={`Example ${index + 1}`} className="w-full h-24 object-cover rounded-lg" />
                        ))}
                    </div>

                    <div className="space-y-4">
                        <div>
                            <h4 className="font-bold flex items-center text-[#414962]">
                                <span className="text-green-500 mr-2">✓</span> Input model name and type
                            </h4>
                            <p className="text-sm text-gray-600">Name your model any name you want, and select the type of subject (Person, Man, Woman)</p>
                        </div>

                        <div>
                            <h4 className="font-bold flex items-center text-[#414962]">
                                <span className="text-green-500 mr-2">✓</span> Choose good pictures
                            </h4>
                            <p className="text-sm text-gray-600">5-10 high-quality samples, front facing, square aspect ratio, 1 person in frame, variety</p>
                            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 my-2">
                                {['/input.png', '/input.png', '/input.png', '/input.png'].map((src, index) => (
                                    <img key={index} src={src} alt={`Good example ${index + 1}`} className="w-full h-24 object-cover rounded-lg" />
                                ))}
                            </div>
                        </div>

                        <div>
                            <h4 className="font-bold flex items-center text-[#414962]">
                                <span className="text-red-500 mr-2">✗</span> Example of bad pictures
                            </h4>
                            <p className="text-sm text-gray-600">Multiple subjects, face covered, NSFW images, blurry, uncropped, full length</p>
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 my-2">
                                {['/not_input.png', '/not_input.png', '/not_input.png'].map((src, index) => (
                                    <img key={index} src={src} alt={`Bad example ${index + 1}`} className="w-full h-24 object-cover rounded-lg" />
                                ))}
                            </div>
                        </div>

                        <div>
                            <h4 className="font-bold flex items-center text-[#414962]">
                                <span className="text-green-500 mr-2">✓</span> Train your model
                            </h4>
                            <p className="text-sm text-gray-600">Training your model takes ~30 minutes. You can leave the page and come back later.</p>
                        </div>

                        <div>
                            <h4 className="font-bold flex items-center text-[#414962]">
                                <span className="text-green-500 mr-2">✓</span> Generate images
                            </h4>
                            <p className="text-sm text-gray-600">Once your model is trained, you can generate images using prompts. Make sure to include the subject keyword in your prompts.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AIModelTrainer;