import axiosApi from "./apiAxios";


export async function login(email, password) {
    const req = await axiosApi.post('/login', { email, password });
    console.log(req.data);

    if (!req.data.data.error) {

        localStorage.setItem("ai-tk", JSON.stringify(req.data.data.token));

    }

    return req.data;
}

export async function logout() {
    localStorage.removeItem("ai-tk");
}



export default function register(user) {
    return axiosApi.post("/register", user)
}

export async function currentUser() {
    const req = await axiosApi.get("/me")
    console.log(req.data);
    return req.data
}




export async function getSubscriptionStatus() {
    const req = await axiosApi.get("/user/latest-subscription")
    console.log(req.data);
    return req.data
}




export async function sendPasswordResetEmail(email) {
    const req = await axiosApi.post("/auth/forget-password", { email })
    return req.data
}

export async function sendResetPassword(payload) {
    const req = await axiosApi.post("/auth/reset-password", payload)
    return req.data
}
