import React, { useEffect, useState } from "react";
import { cn } from "../Lib/Utils";

export default function TypingAnimation({ text = "Give Wings to your Imagination!", duration = 200, className }) {
  const [displayedText, setDisplayedText] = useState([]);

  useEffect(() => {
    const typingEffect = setInterval(() => {
      if (displayedText.length < text.length) {
        setDisplayedText((prev) => [...prev, text[prev.length]]);
      } else {
        clearInterval(typingEffect);
      }
    }, duration);

    return () => {
      clearInterval(typingEffect);
    };
  }, [duration, displayedText, text]);

  const colors = ["text-black"];
//from-[#16B197] to-[#2091DC] 
  return (
    <h1
      className={cn(
        "font-display font-poppins text-center text-4xl font-bold leading-[5rem] tracking-[-0.02em] drop-shadow-sm",
        className
      )}
    >
      {displayedText.map((char, index) => (
        <span key={index} className={colors[index % colors.length]}>
          {char}
        </span>
      ))}
    </h1>
  );
}
