import React from 'react'
import { useLocation } from 'react-router-dom';

const ResponseDisplay = () => {
    const location = useLocation()
    console.log(location)
    const { data} = location.state || { data: [] };
    console.log(data)
    return (
      <div className='mt-10'>
         
          <div>
            <h2 className='text-xl font-bold mb-4'>Generated Captions and Image Prompts</h2>
            <ul>
              {data.map((item, index) => (
                <li key={index} className='mb-4'>
                  <p className='font-semibold'>Caption: {item.caption}</p>
                  <p className='text-gray-600'>Image Prompt: {item.image_prompt}</p>
                </li>
              ))}
            </ul>
          </div>
        
      </div>
    );
  };

export default ResponseDisplay