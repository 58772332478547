import React from 'react';
import { FaHeart, FaRegComment, FaShareSquare, FaBookmark } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

function InstagramBigPost({image, caption}) {
 
  return (
    <div className="max-w-md mx-auto bg-white border rounded-lg shadow-md p-4 mt-2">
      <div className="mb-4">
        <span className="font-bold text-gray-800">#trsg</span>
        <span className="text-gray-500 ml-2">2h</span>
      </div>
      <div>
        <img
        src={image}
        alt='insta'
        />
      </div>
      <p className="text-gray-800 mb-4">
        {caption}
      </p>
      <div className="text-gray-500 text-right mb-4">
        <span>khloeumoh // x</span>
      </div>
      <div className="flex justify-between items-center mt-4 text-gray-800">
        <div className="flex space-x-4">
          <FaHeart className="w-6 h-6" />
          <FaRegComment className="w-6 h-6" />
          <FaShareSquare className="w-6 h-6" />
        </div>
        <FaBookmark className="w-6 h-6" />
      </div>
      <div className="mt-2 text-sm text-gray-600">
        <span>10,241 likes</span>
      </div>
      <div className="text-sm text-gray-500 mt-1">
        <span>View all 26 comments</span>
      </div>
    </div>
  );
}

export default InstagramBigPost;
