import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const GeneratedContentChart = () => {
    // Sample data for posts generated on the platform for each platform
    const labels = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
    const data = {
        labels,
        datasets: [
            {
                label: 'Instagram',
                data: [4, 3, 1, 5, 2, 0, 1, 2, 3, 4, 0, 2, 3, 1, 5, 2, 4, 3, 1, 4, 2, 5, 2, 1, 3, 0, 4, 3, 2, 1, 0],
                borderColor: '#2091DC',
                backgroundColor: 'rgba(32, 145, 220, 0.2)',
                borderWidth: 2,
                fill: true,
            },
            {
                label: 'LinkedIn',
                data: [1, 0, 3, 4, 2, 5, 2, 4, 3, 1, 5, 0, 2, 1, 4, 5, 0, 3, 2, 4, 1, 5, 0, 3, 1, 4, 5, 2, 1, 4, 3],
                borderColor: '#16B197',
                backgroundColor: 'rgba(22, 177, 151, 0.2)',
                borderWidth: 2,
                fill: true,
            },
            {
                label: 'Facebook',
                data: [2, 3, 5, 4, 0, 1, 2, 5, 4, 0, 3, 2, 4, 5, 1, 0, 3, 4, 5, 1, 0, 2, 3, 4, 5, 1, 2, 3, 4, 0, 1],
                borderColor: '#FF5733',
                backgroundColor: 'rgba(255, 87, 51, 0.2)',
                borderWidth: 2,
                fill: true,
            },
            {
                label: 'Pinterest',
                data: [0, 2, 1, 4, 3, 5, 0, 1, 3, 4, 5, 2, 0, 3, 4, 1, 5, 2, 0, 3, 1, 4, 2, 5, 3, 0, 1, 4, 5, 2, 0],
                borderColor: '#C13584',
                backgroundColor: 'rgba(193, 53, 132, 0.2)',
                borderWidth: 2,
                fill: true,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Generated Contents Overview',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 5,
            },
        },
    };

    return (
        <div className="p-6 bg-white rounded-lg shadow-lg">
            <Line data={data} options={options} />
        </div>
    );
};

export default GeneratedContentChart;
